import React, { useContext, useEffect, useRef, useState } from "react";
import { useHistory, useParams, useLocation } from "react-router-dom";
import { ReactReduxContext } from "../../../context/reactReduxContext";
import { setInblockCurrentUser } from "../../../redux/inblock/user/userSlice";
import { jwtFetchData } from "../../../utils/jwtFetchData";
import {
    OpcionesSelectPago2,
    OpcionesSelectDeliberyUnit,
    OpcionesSelectDriver,
    
  } from "../../../utils/selects-opciones";


import { CustomSelect } from "../../custom-select/CustomSelect";
import { ModalRedirect } from "../../modal-redirect/ModalRedirect";

const formInitialState = {
    driver_id: "",
    delibery_unit: "",
    platforms_quantity: "",
    orders: "",
    created_at: "",
    
    
};

export const InblockFormAgregarViaje = ({ idPedido, conductores, formToEdit}) => {
  const [form, setForm] = useState(formInitialState);
  const [showConfirmModal, setShowConfirmModal] = useState(false);

  const {
    dispatch,
    inblock: {
      inblockCurrentUserAccessToken,
      inblockCurrentUserRefreshToken,
      setInblockCurrentUser,
    },
  } = useContext(ReactReduxContext);

  const history = useHistory();
  const { id } = useParams();
  const { pathname } = useLocation();
  const conductorInputRef = useRef();
idPedido= parseInt(idPedido)
let arrayPedido=[idPedido]
//console.log(conductores)
// let conductor
//   useEffect(() => {
 
    // let arrayConductores=[]
    // let c
    // const cond= conductores.map((conductor) => (
    //     key={conductor}
        

        

    // ));
    // console.log(cond)
    // let arr=[1,2,3]
    

    // for (let conductor in cond) {
    //     console.log(conductor)
    //     let option = document.createElement("option");
    //     option.value = conductor;
    //     option.textContent = conductor;
    //     document.querySelector("#conductores-select").appendChild(option);
    //     }

    // for (let c in arrayConductores){
        
        
    //     console.log(c)
    // }


    //   let option = document.createElement("option");
    //   option.value = estado;
    //   option.textContent = estado;
    //   document.querySelector("#estados-select").appendChild(option);
    

//     // document.querySelector("#estados-select").addEventListener("change", e => {
//     //   document.querySelector("#municipios-select").innerHTML =
//     //     "<option></option>";
//     //   for (let municipio of choicesData.Estados[e.target.value]) {
//     //     const option = document.createElement("option");
//     //     option.value = municipio;
//     //     option.textContent = municipio;
//     //     document.querySelector("#municipios-select").appendChild(option);
//     //   }
//     // });

//     const verificarSiClienteExiste = async () => {
//       const json = await jwtFetchData(
//         `${process.env.REACT_APP_INBLOCK_BASE_URL}pedidos/${id}/`,
//         `${process.env.REACT_APP_INBLOCK_BASE_URL}auth/token/refresh/`,
//         inblockCurrentUserAccessToken,
//         inblockCurrentUserRefreshToken,
//         dispatch,
//         setInblockCurrentUser
//       );

//       setForm({
//         access_protocol: json.access_protocol,
//         construction_site_id: json.construction_site_id,
//         delibery_date: json.delibery_date,
//         delibery_hr: json.delibery_hr,
//         general_comments: json.general_comments,
//         material: json.material,
//         material_color: json.material_color,
//         material_measures: json.material_measures,
//         material_use: json.material_use,
//         net_price: json.net_price,
//         payment: json.payment,
//         payment_status: json.payment_status,
//         pieces: json.pieces,
//         platforms: json.platforms,
//         travel_id: json.travel_id,
//         payment_proof: comprobantePagoRef,
//       });

//       if (pagoRef.current.value === "G") {
//         rfcInputRef.current.disabled = true;
//         cfdiInputRef.current.disabled = true;
//         metodoPagoInputRef.current.disabled = true;
//         formaPagoInputRef.current.disabled = true;
//         correoFacturasInputRef.current.disabled = true;
//       }

//       document.querySelector("#municipios-select").addEventListener(
//         "focus",
//         e => {
//           if (
//             choicesData.Estados[estadoInputRef.current.value].includes(
//               json.municipio
//             )
//           ) {
//             document.querySelector(
//               "#municipios-select"
//             ).innerHTML = `<option value="${json.municipio}">${json.municipio}</option>`;
//           } else {
//             console.log("no lo incluye");
//             document.querySelector(
//               "#municipios-select"
//             ).innerHTML = `<option value=""></option>`;
//           }

//           for (let municipio of choicesData.Estados[
//             estadoInputRef.current.value
//           ]) {
//             const option = document.createElement("option");
//             option.value = municipio;
//             option.textContent = municipio;
//             document.querySelector("#municipios-select").appendChild(option);
//           }
//         },
//         { once: true }
//       );
//     };

//     if (id) {
//       verificarSiClienteExiste();
//     }
//   }, []);

  const handleChange = e => {
    const value = e.target.value;
    const name = e.target.name;

    // if (e.target.name === "pago") {
    //   if (e.target.value === "G") {
    //     setForm({
    //       ...form,
    //       pago: "G",
    //       rfc: "",
    //       CFDI: "",
    //       metodo_pago: "",
    //       forma_pago: "",
    //       correo_facturas: "",
    //     });

    //     rfcInputRef.current.disabled = true;
    //     cfdiInputRef.current.disabled = true;
    //     metodoPagoInputRef.current.disabled = true;
    //     formaPagoInputRef.current.disabled = true;
    //     correoFacturasInputRef.current.disabled = true;
    //   } else {
    //     setForm({
    //       ...form,
    //       pago: "F",
    //       CFDI: cfdiInputRef.current.value,
    //       metodo_pago: metodoPagoInputRef.current.value,
    //       forma_pago: formaPagoInputRef.current.value,
    //     });

    //     rfcInputRef.current.disabled = false;
    //     cfdiInputRef.current.disabled = false;
    //     metodoPagoInputRef.current.disabled = false;
    //     formaPagoInputRef.current.disabled = false;
    //     correoFacturasInputRef.current.disabled = false;
    //   }

    //   return;
    // }

    // if (e.target.name === "") {
    //   setForm({
    //     ...form,
    //     estado_republica: estadoInputRef.current.value,
    //     municipio: "",
    //   });

    //   return;
    // }

    setForm({
      ...form,
      [name]: value,
    });
  };

  const handleSubmit = async (e, historyRef) => {
    e.preventDefault();

    const formulario = {
    //   driver_id: form.driver_id,
      driver_id: form.driver_id,
      delibery_unit: form.delibery_unit,
      platforms_quantity: form.platforms_quantity,
      orders: arrayPedido,
    };

    if (formToEdit) {
      let data = await fetch(
        `${process.env.REACT_APP_INBLOCK_BASE_URL}pedidos/${id}`,
        {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${inblockCurrentUserAccessToken}`,
          },
          body: JSON.stringify(formulario),
        }
      );

      let json = await data.json();

      if (data.status === 401) {
        data = await fetch(
          `${process.env.REACT_APP_INBLOCK_BASE_URL}auth/token/refresh/`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ refresh: inblockCurrentUserRefreshToken }),
          }
        );

        json = await data.json();

        if (data.status === 200) {
          data = await fetch(
            `${process.env.REACT_APP_INBLOCK_BASE_URL}pedidos/${id}`,
            {
              method: "PATCH",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${inblockCurrentUserAccessToken}`,
              },
              body: JSON.stringify(formulario),
            }
          );

          json = await data.json();

          dispatch(
            setInblockCurrentUser({
              access: json.access,
              refresh: inblockCurrentUserRefreshToken,
            })
          );
        }
      }

      if (data.status === 400) {
        if (json.email) {
          alert("Este correo ya ha sido registrado");
        }
        if (json.telefono) {
          alert("Este teléfono ya ha sido registrado");
          return;
        } else {
          alert(JSON.stringify(json));
        }
      }

      if (data.status === 201) {
        setShowConfirmModal(true);
      }
      if (data.status === 200) {
        setShowConfirmModal(true);
      }

      if (data.status === 406) {
        alert(json.error);
      }
    } else {
      let data = await fetch(
        `${process.env.REACT_APP_INBLOCK_BASE_URL}pedidos/registrar-viaje`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${inblockCurrentUserAccessToken}`,
          },
          body: JSON.stringify(formulario),
        }
      );

      let json = await data.json();

      if (data.status === 401) {
        data = await fetch(
          `${process.env.REACT_APP_INBLOCK_BASE_URL}auth/token/refresh/`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ refresh: inblockCurrentUserRefreshToken }),
          }
        );

        json = await data.json();

        if (data.status === 200) {
          data = await fetch(
            `${process.env.REACT_APP_INBLOCK_BASE_URL}pedidos/registrar-viaje`,
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${inblockCurrentUserAccessToken}`,
              },
              body: JSON.stringify(formulario),
            }
          );

          json = await data.json();

          dispatch(
            setInblockCurrentUser({
              access: json.access,
              refresh: inblockCurrentUserRefreshToken,
            })
          );
        }
      }

      if (data.status === 400) {
        if (json.email) {
          alert("Este correo ya ha sido registrado");
        }
        if (json.telefono) {
          alert("Este teléfono ya ha sido registrado");
          return;
        } else {
          alert(JSON.stringify(json));
        }
      }

      if (data.status === 201 || data.status === 200) {
        setShowConfirmModal(true);
      }

      if (data.status === 406) {
        alert(json.error);
      }
    }
  };

  return (
    <div className="container">
      <ModalRedirect
        showConfirmModal={showConfirmModal}
        text={
          formToEdit
            ? "Se han actualizado correctamente el viaje."
            : "Se ha creado correctamente el viaje."
        }
        link={
          formToEdit
            ? pathname.includes("logistica")
              ? pathname.includes("realizar-pedido")
                ? `/inblock/logistica/pedido/${id}`
                : `/inblock/logistica/pedido/${id}`
              : pathname.includes("comercializacion") &&
                `/inblock/comercializacion/cliente/${id}`
            : pathname.includes("logistica")
            ? `/inblock/logistica/viajes`
              : `inblock/logistica/viajes`
            
        }
      />
      <div className="row justify-content-center">
        <div className="col-12 col-sm-8">
          {formToEdit ? (
            <h3 className="text-center">Editar Viaje</h3>
          ) : (
            <h3 className="text-center">Agregar Viaje</h3>
          )}

          <form
            className="agregar-cliente-form"
            onSubmit={e => {
              handleSubmit(e, history);
            }}
          >
            
            <div className="mb-2">
              <label htmlFor="platforms_quantity" className="form-label">
                Tarimas
              </label>
              <input
                type="text"
                name="platforms_quantity"
                id="platforms_quantity"
                value={form.platforms_quantity}
                onChange={handleChange}
                className="form-control"
                autoComplete="off"
                required
              />
            </div> 

            <div className="mb-2">
                <CustomSelect
                  datos={OpcionesSelectDeliberyUnit}
                  handleChange={handleChange}
                  isRequired={true}
                  form={form}
                />
              </div>

              <div className="mb-2">
                <CustomSelect
                  datos={OpcionesSelectDriver}
                  handleChange={handleChange}
                  isRequired={true}
                  form={form}
                />
              </div>

              
              {/* <div className="mb-2">
                <label htmlFor="conductores-select" className="form-label">
                  Estado de la República
                </label>
                <select
                  id="conductores-select"
                  name="driver_id"
                  onChange={handleChange}
                  ref={conductorInputRef}
                  value={form && form.driver_id}
                  className="form-select"
                  required
                >
                  <option></option>
                </select>
              </div> */}
           

            
            {/* <div className="mb-2">
                <CustomSelect
                  datos={OpcionesSelectMaterialColor}
                  handleChange={handleChange}
                  isRequired={true}
                  form={form}
                />
              </div> */}

            

            





            <div className="d-flex justify-content-end ">
              {formToEdit ? (
                <input
                  type="submit"
                  value="Guardar Cambios"
                  className="btn  mb-3 mt-2"
                  style={{ backgroundColor: "#6c757d", color: "white" }}
                />
              ) : (
                <input
                  type="submit"
                  value="Agregar"
                  className="btn mb-3 mt-2"
                  style={{ backgroundColor: "#6c757d", color: "white" }}
                />
              )}
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};