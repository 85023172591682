import React from "react";

import Table from "react-bootstrap/Table";
import { Link, useLocation } from "react-router-dom";

export const InblockTablaConductores = ({ conductores }) => {
  const { pathname } = useLocation();
  return (
    <div className="table-responsive p-0">
      <Table striped bordered hover className="text-center ">
        <thead>
          <tr>
            <th>ID</th>
            <th>Nombre</th>
          </tr>
        </thead>
        <tbody className="text-reset">
          {conductores.map((conductor) => (
            <tr key={conductor.id} className="table-row">
              <td>
              {pathname.includes("pedido") && (
                <Link to={`/inblock/logistica/cliente/${conductor.id}/realizar-pedido`}>
                  {conductor.id}
                </Link>
              )}
              {pathname.includes("logistica") && !pathname.includes("pedido") && (
                <Link to={`/inblock/logistica/cliente/${conductor.id}`}>
                  {conductor.id}
                </Link>
              )}
              

              </td>
              <td>
              {pathname.includes("pedido") && (
                <Link to={`/inblock/logistica/cliente/${conductor.id}/realizar-pedido`}>
                  {conductor.fullname}
                </Link>
              )}
              {pathname.includes("logistica") && !pathname.includes("pedido") && (
                <Link to={`/inblock/logistica/cliente/${conductor.id}`}>
                  {conductor.fullname}
                </Link>
              )}
              </td>
             
              {/* <td>
              {pathname.includes("pedido") && (
                <Link to={`/inblock/logistica/cliente/${cliente.id}/realizar-pedido`}>
                  {cliente.created_at.slice(0, 10)}
                </Link>
              )}
              {pathname.includes("logistica") && !pathname.includes("pedido") && (
                <Link to={`/inblock/logistica/cliente/${cliente.id}`}>
                  {cliente.created_at.slice(0, 10)}
                </Link>
              )}                
 
              </td> */}
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
};