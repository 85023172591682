export const markerSvgPaths = {
  pathPinStar:
    "M250,69.44A140.09,140.09,0,0,0,139.3,295.37l4.78,5.83,0,.05L250,430.57,355.89,301.25l0-.05,4.78-5.83A140.09,140.09,0,0,0,250,69.44Zm55.7,219.07-53.91-27.46-53.18,28.83,9.46-59.75-43.86-41.67L224,179,250,124.41l27.47,53.9,60,7.93L294.71,229Z",
  pathPinGuion:
    "M140.08,0A140.09,140.09,0,0,0,29.38,225.93l4.78,5.83,0,.05L140.09,361.13,246,231.81l0-.05,4.78-5.83A140.09,140.09,0,0,0,140.08,0Zm76.2,161.72a26.36,26.36,0,0,1-18.67,7.73H82.55a26.4,26.4,0,0,1,0-52.8H197.61a26.4,26.4,0,0,1,18.67,45.07Z",
  pathPinFail:
    "M250,69.44A140.09,140.09,0,0,0,139.3,295.37l4.78,5.83,0,.05L250,430.57,355.89,301.25l0-.05,4.78-5.83A140.09,140.09,0,0,0,250,69.44Zm67,210.09a18,18,0,0,1-25.43,0L250,237.93l-41.61,41.6A18,18,0,1,1,183,254.09l41.6-41.6L183,170.89a18,18,0,1,1,25.43-25.43L250,187.06l41.6-41.6A18,18,0,0,1,317,170.89l-41.6,41.6,41.6,41.6A18,18,0,0,1,317,279.53Z",
  pathPinActive:
    "M10.453 14.016l6.563-6.609-1.406-1.406-5.156 5.203-2.063-2.109-1.406 1.406zM12 2.016q2.906 0 4.945 2.039t2.039 4.945q0 1.453-0.727 3.328t-1.758 3.516-2.039 3.070-1.711 2.273l-0.75 0.797q-0.281-0.328-0.75-0.867t-1.688-2.156-2.133-3.141-1.664-3.445-0.75-3.375q0-2.906 2.039-4.945t4.945-2.039z",
};
