import React from "react";
import { useParams } from "react-router-dom";
import { InblockListaObrasDeCliente } from "../../../components/inblock/inblock-lista-obras-de-cliente/InblockListaObrasDeCliente";
import { LoadingSpinner } from "../../../components/loading-spinner/LoadingSpinner";
import { useJwtFetchAndLoading } from "../../../hooks/useJwtFetchAndLoading";

export const InblockObrasDeCliente = () => {
  const { id } = useParams();

  const { data, isLoading } = useJwtFetchAndLoading(
    `${process.env.REACT_APP_INBLOCK_BASE_URL}clientes/${id}/obras`,
    id
  );

  return isLoading ? (
    <LoadingSpinner />
  ) : (
    <>
      <InblockListaObrasDeCliente id={id} data={data} />
    </>
  );
};
