import React, { useContext, useEffect, useRef, useState } from "react";
import { useHistory, useParams, useLocation } from "react-router-dom";
import { ReactReduxContext } from "../../../context/reactReduxContext";
import { setInblockCurrentUser } from "../../../redux/inblock/user/userSlice";
import { jwtFetchData } from "../../../utils/jwtFetchData";
import {
    OpcionesSelectPago2,
    OpcionesSelectCfdi,
    OpcionesSelectMetodoPago,
    OpcionesSelectFormaPago,
    OpcionesSelectContacto,
    OpcionesSelectGiro,
    OpcionesSelectTipoVenta,
    OpcionesSelectUsoMaterial,
    Payment,
    PaymentStatus,
    DeliberyHour,
    OpcionesSelectAccessProtocol,
    OpcionesSelectMaterialColor,
    OpcionesSelectMaterial,
    OpcionesSelectMaterialMeasures,
    OpcionesSelectDeliberyUnit,
    OpcionesTownship
  } from "../../../utils/selects-opciones";


import { CustomSelect } from "../../custom-select/CustomSelect";
import { ModalRedirect } from "../../modal-redirect/ModalRedirect";

const formInitialState = {
    access_protocol: "",
    construction_site_id: "",
    created_at: "",
    delibery_date: "",
    delibery_hr: "",
    general_comments: "",
    material: "",
    material_color: "",
    material_measures: "",
    material_use: "",
    net_price: "",
    payment: "",
    payment_status: "",
    pieces: "",
    // platforms: "",
    delibery_unit: "",
    township:"",
    travel_id: null,
    
};

export const InblockFormAgregarPedido = ({ choicesData, formToEdit, idObra, idCondicion }) => {
  const [form, setForm] = useState(formInitialState);
  const [showConfirmModal, setShowConfirmModal] = useState(false);

  const {
    dispatch,
    inblock: {
      inblockCurrentUserAccessToken,
      inblockCurrentUserRefreshToken,
      setInblockCurrentUser,
    },
  } = useContext(ReactReduxContext);

  const history = useHistory();
  const { id } = useParams();
  const { pathname } = useLocation();

  

  const materialRef = useRef();
  const municipioRef = useRef();
  const rfcInputRef = useRef();
  const cfdiInputRef = useRef();
  const metodoPagoInputRef = useRef();
  const formaPagoInputRef = useRef();
  const correoFacturasInputRef = useRef();
  const estadoInputRef = useRef();
  const municipioInputRef = useRef();
  const comprobantePagoRef = useRef();
  const deliberyUnitRef = useRef();
  const townshipRef = useRef();
  const ordenCompraRef = useRef();

  useEffect(() => {
    // for (let estado in choicesData.Estados) {
    //   let option = document.createElement("option");
    //   option.value = estado;
    //   option.textContent = estado;
    //   document.querySelector("#estados-select").appendChild(option);
    // }

    // document.querySelector("#estados-select").addEventListener("change", e => {
    //   document.querySelector("#municipios-select").innerHTML =
    //     "<option></option>";
    //   for (let municipio of choicesData.Estados[e.target.value]) {
    //     const option = document.createElement("option");
    //     option.value = municipio;
    //     option.textContent = municipio;
    //     document.querySelector("#municipios-select").appendChild(option);
    //   }
    // });

    const verificarSiClienteExiste = async () => {
      const json = await jwtFetchData(
        `${process.env.REACT_APP_INBLOCK_BASE_URL}pedidos/${id}/`,
        `${process.env.REACT_APP_INBLOCK_BASE_URL}auth/token/refresh/`,
        inblockCurrentUserAccessToken,
        inblockCurrentUserRefreshToken,
        dispatch,
        setInblockCurrentUser
      );

      setForm({
        access_protocol: json.access_protocol,
        construction_site_id: json.construction_site_id,
        delibery_date: json.delibery_date,
        delibery_hr: json.delibery_hr,
        general_comments: json.general_comments,
        material: json.material,
        material_color: json.material_color,
        material_measures: json.material_measures,
        material_use: json.material_use,
        net_price: json.net_price,
        payment: json.payment,
        payment_status: json.payment_status,
        pieces: json.pieces,
        // platforms: json.platforms,
        travel_id: json.travel_id,
        delibery_unit: json.delibery_unit,
        township:json.township,
        payment_proof: comprobantePagoRef,
        purchase_order: ordenCompraRef,
      });

      // if (pagoRef.current.value === "G") {
      //   rfcInputRef.current.disabled = true;
      //   cfdiInputRef.current.disabled = true;
      //   metodoPagoInputRef.current.disabled = true;
      //   formaPagoInputRef.current.disabled = true;
      //   correoFacturasInputRef.current.disabled = true;
      // }

      // document.querySelector("#municipios-select").addEventListener(
      //   "focus",
      //   e => {
      //     if (
      //       choicesData.Estados[estadoInputRef.current.value].includes(
      //         json.municipio
      //       )
      //     ) {
      //       document.querySelector(
      //         "#municipios-select"
      //       ).innerHTML = `<option value="${json.municipio}">${json.municipio}</option>`;
      //     } else {
      //       console.log("no lo incluye");
      //       document.querySelector(
      //         "#municipios-select"
      //       ).innerHTML = `<option value=""></option>`;
      //     }

      //     for (let municipio of choicesData.Estados[
      //       estadoInputRef.current.value
      //     ]) {
      //       const option = document.createElement("option");
      //       option.value = municipio;
      //       option.textContent = municipio;
      //       document.querySelector("#municipios-select").appendChild(option);
      //     }
      //   },
      //   { once: true }
      // );
    };

    if (id) {
      verificarSiClienteExiste();
    }
  }, []);

  const handleChange = e => {
    const value = e.target.value;
    const name = e.target.name;
    
    if (e.target.name === "material") {
      if (e.target.value === "Block Pesado" || e.target.value === "Block Ligero" ) {
        
        
        document.querySelector("#material-measures").innerHTML = `
        <option value=""></option>
        <option value="10x20x40">10x20x40</option>
        <option value="12x20x40">12x20x40</option>
        <option value="15x20x40">15x20x40</option>
        <option value="20x20x40">20x20x40</option>
        `;
      }else if (e.target.value === "Tabicón Ligero" || e.target.value === "Tabicón Pesado") {
        document.querySelector("#material-measures").innerHTML = `
        <option value=""></option>
        <option value="10x14x28">10x14x28</option>
        `;
      }else if (e.target.value === "Block Cara de Piedra" || e.target.value === "Block Tipo L") {
        document.querySelector("#material-measures").innerHTML = `
        <option value=""></option>
        <option value="15x20x40">15x20x40</option>
        `;
      }else if (e.target.value === "Adoquín") {
        document.querySelector("#material-measures").innerHTML = `
        <option value=""></option>
        <option value="10x8x20">10x8x20</option>
        `;
      }else if (e.target.value === "Loseta" || e.target.value === "Block Tipo U" || e.target.value === "Block Ranurado") {
        document.querySelector("#material-measures").innerHTML = `
        <option value=""></option>
        <option value="20x20x4">20x20x4</option>
        `;
      }else if (e.target.value === "Recintocreto") {
        document.querySelector("#material-measures").innerHTML = `
        <option value=""></option>
        <option value="40x40x7">40x40x7</option>
        `;
      }else if (e.target.value === "Tabicón sólido ligero") {
        document.querySelector("#material-measures").innerHTML = `
        <option value=""></option>
        <option value="12x20x40">12x20x40</option>
        <option value="15x20x40">15x20x40</option>
        `;
      }else if (e.target.value === "Mitad de block") {
        document.querySelector("#material-measures").innerHTML = `
        <option value=""></option>
        <option value="10x20x20">10x20x20</option>
        <option value="12x20x20">12x20x20</option>
        <option value="15x20x20">15x20x20</option>
        <option value="20x20x20">20x20x20</option>
        `;
      }
      
      else {
        document.querySelector("#material-measures").innerHTML = `
        <option value=""></option>`;
      }

      setForm({
          ...form,
          //  material: materialRef.current.value,
          material: e.target.value,
          material_measures: "",
        });

        console.log(materialRef.current.value)


      return;
    }

    if (e.target.name === "delibery_unit") {
      if (e.target.value === "Cargado en planta" ) {
        setForm({
          ...form,
          delibery_unit: e.target.value,
          township: "",
          
        });

        townshipRef.current.disabled = true;
        
      }else{
        setForm({
          ...form,
          delibery_unit: e.target.value,
          township: "",
          
        });
        townshipRef.current.disabled = false;

      }

      console.log("si")
      return;
    }



    // if (e.target.name === "pago") {
    //   if (e.target.value === "G") {
    //     setForm({
    //       ...form,
    //       pago: "G",
    //       rfc: "",
    //       CFDI: "",
    //       metodo_pago: "",
    //       forma_pago: "",
    //       correo_facturas: "",
    //     });

    //     rfcInputRef.current.disabled = true;
    //     cfdiInputRef.current.disabled = true;
    //     metodoPagoInputRef.current.disabled = true;
    //     formaPagoInputRef.current.disabled = true;
    //     correoFacturasInputRef.current.disabled = true;
    //   } else {
    //     setForm({
    //       ...form,
    //       pago: "F",
    //       CFDI: cfdiInputRef.current.value,
    //       metodo_pago: metodoPagoInputRef.current.value,
    //       forma_pago: formaPagoInputRef.current.value,
    //     });

    //     rfcInputRef.current.disabled = false;
    //     cfdiInputRef.current.disabled = false;
    //     metodoPagoInputRef.current.disabled = false;
    //     formaPagoInputRef.current.disabled = false;
    //     correoFacturasInputRef.current.disabled = false;
    //   }

    //   return;
    // }

    // if (e.target.name === "estado_republica") {
    //   setForm({
    //     ...form,
    //     estado_republica: estadoInputRef.current.value,
    //     municipio: "",
    //   });

    //   return;
    // }

    setForm({
      ...form,
      [name]: value,
    });
  };

  const handleSubmit = async (e, historyRef) => {
    e.preventDefault();

    const formulario = {
      access_protocol: form.access_protocol,
      general_comments: form.general_comments,
      // payment_fiscal: form.pago === "F" ? true : false,
      delibery_date: form.delibery_date,
      delibery_hr: form.delibery_hr,
      material: form.material,
      material_color: form.material_color,
      material_measures: form.material_measures,
      material_use: form.material_use,
      net_price: form.net_price,
      construction_site_id : idObra,
      condition_id : idCondicion, 
      payment: form.payment,
      payment_status: form.payment_status,
      pieces: form.pieces,
      // platforms: form.platforms,
      delibery_unit: form.delibery_unit,
      township: form.township,
      travel_id: form.travel_id,
    };

    if (formToEdit) {
      let data = await fetch(
        `${process.env.REACT_APP_INBLOCK_BASE_URL}pedidos/${id}`,
        {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${inblockCurrentUserAccessToken}`,
          },
          body: JSON.stringify(formulario),
        }
      );

      let json = await data.json();

      if (data.status === 401) {
        data = await fetch(
          `${process.env.REACT_APP_INBLOCK_BASE_URL}auth/token/refresh/`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ refresh: inblockCurrentUserRefreshToken }),
          }
        );

        json = await data.json();

        if (data.status === 200) {
          data = await fetch(
            `${process.env.REACT_APP_INBLOCK_BASE_URL}pedidos/${id}`,
            {
              method: "PATCH",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${inblockCurrentUserAccessToken}`,
              },
              body: JSON.stringify(formulario),
            }
          );

          json = await data.json();

          dispatch(
            setInblockCurrentUser({
              access: json.access,
              refresh: inblockCurrentUserRefreshToken,
            })
          );
        }
      }

      if (data.status === 400) {
        if (json.email) {
          alert("Este correo ya ha sido registrado");
        }
        if (json.telefono) {
          alert("Este teléfono ya ha sido registrado");
          return;
        } else {
          alert(JSON.stringify(json));
        }
      }

      if (data.status === 201) {
        setShowConfirmModal(true);
      }
      if (data.status === 200) {
        setShowConfirmModal(true);
      }

      if (data.status === 406) {
        alert(json.error);
      }
    } else {
      let data = await fetch(
        `${process.env.REACT_APP_INBLOCK_BASE_URL}pedidos/`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${inblockCurrentUserAccessToken}`,
          },
          body: JSON.stringify(formulario),
        }
      );

      let json = await data.json();

      if (data.status === 401) {
        data = await fetch(
          `${process.env.REACT_APP_INBLOCK_BASE_URL}auth/token/refresh/`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ refresh: inblockCurrentUserRefreshToken }),
          }
        );

        json = await data.json();

        if (data.status === 200) {
          data = await fetch(
            `${process.env.REACT_APP_INBLOCK_BASE_URL}pedidos/`,
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${inblockCurrentUserAccessToken}`,
              },
              body: JSON.stringify(formulario),
            }
          );

          json = await data.json();

          dispatch(
            setInblockCurrentUser({
              access: json.access,
              refresh: inblockCurrentUserRefreshToken,
            })
          );
        }
      }

      if (data.status === 400) {
        if (json.email) {
          alert("Este correo ya ha sido registrado");
        }
        if (json.telefono) {
          alert("Este teléfono ya ha sido registrado");
          return;
        } else {
          alert(JSON.stringify(json));
        }
      }

      if (data.status === 201) {
        setShowConfirmModal(true);
      }

      if (data.status === 406) {
        alert(json.error);
      }
    }
  };

  return (
    <div className="container">
      <ModalRedirect
        showConfirmModal={showConfirmModal}
        text={
          formToEdit
            ? "Se han actualizado correctamente el pedido."
            : "Se ha creado correctamente el pedido."
        }
        link={
          formToEdit
            ? pathname.includes("logistica")
              ? pathname.includes("realizar-pedido")
                ? `/inblock/logistica/pedido/${id}`
                : `/inblock/logistica/pedido/${id}`
              : pathname.includes("comercializacion") &&
                `/inblock/comercializacion/cliente/${id}`
            : pathname.includes("logistica")
            ? pathname.includes("form-pedido")
              ? `/inblock/logistica/pedidos`
              : `inblock/logistica/pedidos`
            : pathname.includes("comercializacion") &&
              "/inblock/comercializacion/clientes"
        }
      />
      <div className="row justify-content-center">
        <div className="col-12 col-sm-8">
          {formToEdit ? (
            <h3 className="text-center">Editar Pedido</h3>
          ) : (
            <h3 className="text-center">Agregar Pedido</h3>
          )}

          <form
            className="agregar-cliente-form"
            onSubmit={e => {
              handleSubmit(e, history);
            }}
          >
            
            {/* <div className="mb-2">
              <label htmlFor="material" className="form-label">
                Material
              </label>
              <input
                type="text"
                name="material"
                id="material"
                value={form.material}
                onChange={handleChange}
                className="form-control"
                autoComplete="off"
                required
              />
            </div>  */}
            <div className="mb-2">
                <CustomSelect
                  datos={OpcionesSelectMaterial}
                  handleChange={handleChange}
                  // value={form.material}
                  isRequired={true}
                  form={form}
                  // selectRef={materialRef}
                  
                />
              </div>

              {/* <div className="mb-2">
              <label htmlFor="material" className="form-label">
                Tipo de Bomba
              </label>
              <select
                id="material"
                name="material"
                onChange={handleChange}
                value={form.material}
                ref={materialRef}
                className="form-select"
                required
              >
                <option value=""></option>
                <option value="Block Pesado">Block Pesado</option>
                <option value="Tabicón Pesado">Block Ligero</option>
              </select>
            </div> */}

            {/* <div className="mb-2">
              <label htmlFor="material_color" className="form-label">
                Color del material
              </label>
              <input
                type="text"
                name="material_color"
                id="material_color"
                value={form.material_color}
                onChange={handleChange}
                className="form-control"
                autoComplete="off"
                required
              />
            </div> */}
            <div className="mb-2">
                <CustomSelect
                  datos={OpcionesSelectMaterialColor}
                  handleChange={handleChange}
                  isRequired={true}
                  form={form}
                  // value={form.material}
                />
              </div>
            

            {/* <div className="mb-2">
              <label htmlFor="material_measures" className="form-label">
                Medidas del material
              </label>
              <input
                type="text"
                name="material_measures"
                id="material_measures"
                value={form.material_measures}
                onChange={handleChange}
                className="form-control"
                autoComplete="off"
                required
              />
            </div> */}
            <div className="mb-2">
                <CustomSelect
                  datos={OpcionesSelectMaterialMeasures}
                  handleChange={handleChange}
                  isRequired={true}
                  form={form}
                  selectRef={materialRef}
                  
                />
              </div>

            <div className="mb-2">
              <label htmlFor="pieces" className="form-label">
                Piezas
              </label>
              <input
                type="text"
                name="pieces"
                id="pieces"
                value={form.pieces}
                onChange={handleChange}
                className="form-control"
                autoComplete="off"
                required
              />
            </div>

            <div className="mb-2">
                <CustomSelect
                  datos={OpcionesSelectUsoMaterial}
                  handleChange={handleChange}
                  isRequired={true}
                  form={form}
                />
              </div>

              {/* <div className="mb-2">
              <label htmlFor="platforms" className="form-label">
                Tarimas
              </label>
              <input
                type="text"
                name="platforms"
                id="platforms"
                value={form.platforms}
                onChange={handleChange}
                className="form-control"
                autoComplete="off"
                required
              />
            </div> */}

            <div className="mb-2">
                <CustomSelect
                  datos={OpcionesSelectDeliberyUnit}
                  handleChange={handleChange}
                  isRequired={true}
                  form={form}
                />
              </div>

              <div className="mb-2">
                <CustomSelect
                  datos={OpcionesTownship}
                  handleChange={handleChange}
                  isRequired={false}
                  form={form}
                  selectRef={townshipRef}
                />
              </div>

            <div className="mb-2">
                <CustomSelect
                  datos={DeliberyHour}
                  handleChange={handleChange}
                  isRequired={true}
                  form={form}
                />
              </div>

            <div className="mb-2">
              <label htmlFor="net_price" className="form-label">
                Precio neto
              </label>
              <input
                type="text"
                name="net_price"
                id="net_price"
                value={form.net_price}
                onChange={handleChange}
                className="form-control"
                autoComplete="off"
                required
              />
            </div>

            <div className="mb-2">
              <label htmlFor="delibery_date" className="form-label">
                Fecha Pedido
              </label>
              <input
                type="date"
                name="delibery_date"
                id="delibery_date"
                value={form.delibery_date}
                onChange={handleChange}
                className="form-control"
                autoComplete="off"
                required
              />
            </div>

            <div className="mb-2">
                <CustomSelect
                  datos={Payment}
                  handleChange={handleChange}
                  isRequired={true}
                  form={form}
                />
              </div>

              <div className="mb-2">
                <CustomSelect
                  datos={PaymentStatus}
                  handleChange={handleChange}
                  isRequired={true}
                  form={form}
                />
              </div>
            
              

              <div className="mb-2">
                <CustomSelect
                  datos={OpcionesSelectAccessProtocol}
                  handleChange={handleChange}
                  isRequired={true}
                  form={form}
                />
              </div>

              <div className="mb-2">
              <label htmlFor="general_comments" className="form-label">
                Comentarios Generales
              </label>
              <input
                type="text"
                name="general_comments"
                id="general_comments"
                value={form.general_comments}
                onChange={handleChange}
                className="form-control"
                autoComplete="off"
                required
              />
            </div>

            <div className="mb-2">
              <label htmlFor="comprobante" className="form-label">
                Comprobante de pago
              </label>
              <input
                type="file"
                id="comprobante"
                className="form-control"
                ref={comprobantePagoRef}
              />
            </div>

            <div className="mb-2">
              <label htmlFor="orden_compra" className="form-label">
                Orden de Compra
              </label>
              <input
                type="file"
                id="orden_compra"
                className="form-control"
                ref={ordenCompraRef}
              />
            </div>




             





            <div className="d-flex justify-content-end ">
              {formToEdit ? (
                <input
                  type="submit"
                  value="Guardar Cambios"
                  className="btn  mb-3 mt-2"
                  style={{ backgroundColor: "#6c757d", color: "white" }}
                />
              ) : (
                <input
                  type="submit"
                  value="Agregar"
                  className="btn mb-3 mt-2"
                  style={{ backgroundColor: "#6c757d", color: "white" }}
                />
              )}
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};