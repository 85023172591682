import React from "react";

import { useIsDesktop } from "../../../hooks/useIsDesktop";
import { InblockErrorBoundary } from "../inblock-error-boundary/InblockErrorBondary";
import { InblockHeaderMobile } from "../inblock-header-mobile/InblockHeaderMobile";
import { InblockHeader } from "../inblock-header/InblockHeader";
import { InblockSidebar } from "../inblock-sidebar/InblockSidebar";

export const InblockLayout = ({ children, sectionTitle, navLinksArr }) => {
  const isDesktop = useIsDesktop();

  return (
    <div className="homepage-container">
      {isDesktop && (
        <div className="sidebar-maincontent-container">
          <InblockSidebar navigationLinks={navLinksArr} />
        </div>
      )}

      <div className="header-content-container">
        {isDesktop ? (
          <InblockHeader title={sectionTitle} />
        ) : (
          <InblockHeaderMobile title={sectionTitle} links={navLinksArr} />
        )}

        <div className={`${isDesktop ? "main-content" : "mobile-content"}`}>
          <InblockErrorBoundary>{children}</InblockErrorBoundary>
        </div>
      </div>
    </div>
  );
};
