import React from "react";
import { InblockFormAgregarUsuario } from "../../../components/inblock/inblock-form-agregar-usuario/InblockFormAgregarUsuario";

export const InblockAgregarUsuarioPage = () => {
  return (
    <>
      <InblockFormAgregarUsuario />
    </>
  );
};
