import React, { useState, useRef } from "react";
import { useDispatch } from "react-redux";

import { setInblockCurrentUser } from "../../../redux/inblock/user/userSlice";

import Spinner from "react-bootstrap/Spinner";
import Button from "react-bootstrap/Button";

import styles from "./InblockLoginForm.module.scss";

const initialState = {
  username: "",
  password: "",
};

export const InblockLoginForm = () => {
  const [form, setForm] = useState(initialState);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();

  const errorMessageRef = useRef();

  const handleSumbit = async (e) => {
    e.preventDefault();

    setIsLoading(true);

    errorMessageRef.current.classList.add(`${styles.hidden}`);

    const { username, password } = form;

    try {
      const data = await fetch(
        process.env.REACT_APP_INBLOCK_BASE_URL + `auth/login/`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            username,
            password,
          }),
        }
      );

      const json = await data.json();

      if (data.status === 200) {
        dispatch(setInblockCurrentUser(json));
      }

      if (data.status === 401) {
        errorMessageRef.current.classList.remove(`${styles.hidden}`);
        setForm(initialState);
        setIsLoading(false);
      }

      if (data.status !== 200 && data.status !== 401) {
        setForm(initialState);
        setIsLoading(false);
        console.log(json);
        alert(json);
      }
    } catch (error) {
      alert(error);
      console.log(error);
      setForm(initialState);
      setIsLoading(false);
    }
  };

  const handleChange = (e) => {
    const { value, name } = e.target;
    setForm({ ...form, [name]: value });
  };

  return (
    <>
      <form onSubmit={handleSumbit}>
        <input
          type="text"
          name="username"
          required
          value={form.username}
          onChange={handleChange}
          className={`form-control ${styles.loginUserInput}`}
          placeholder="Usuario"
        ></input>
        <input
          type="password"
          name="password"
          required
          value={form.password}
          onChange={handleChange}
          id=""
          className="form-control login-input"
          placeholder="Contraseña"
        ></input>
        <p
          className={`${styles.badUserPassMsg} ${styles.hidden}`}
          ref={errorMessageRef}
        >
          Usuario o contraseña incorrectos
        </p>

        {!isLoading ? (
          <button
            type="submit"
            className={`btn btn-secondary ${styles.loginSubmit}`}
          >
            Ingresar
          </button>
        ) : (
          <Button variant="secondary" className={styles.loginSubmit} disabled>
            <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
            />
            Ingresando...
          </Button>
        )}
      </form>
    </>
  );
};
