import React, { useContext, useEffect, useState } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";

/* 
import { SearchInput } from "../../components/search-input/SearchInput";
 */

import { BotonesPaginacionTablas } from "../../../components/botones-paginacion-tablas/BotonesPaginacionTablas";
import { jwtFetchData } from "../../../utils/jwtFetchData";
import { LoadingSpinner } from "../../../components/loading-spinner/LoadingSpinner";
import { ReactReduxContext } from "../../../context/reactReduxContext";
import { InblockTablaObrasDeCliente } from "../inblock-tabla-obras-de-cliente/InblockTablaObrasDeCliente";

export const InblockListaObrasDeCliente = ({ id, data }) => {
  
  const [obras, setObras] = useState(data.results);
  const [currentPage, setCurrentPage] = useState(1);
  const [finalPage, setFinalPage] = useState(Math.ceil(data.count / 10));
  const [isLoading, setIsLoading] = useState(false);
  const [busquedaAplicada, setBusquedaAplicada] = useState("");

  const history = useHistory();

  const { pathname } = useLocation();

  const {
    dispatch,
    inblock: {
      setInblockCurrentUser,
      inblockCurrentUserAccessToken,
      inblockCurrentUserRefreshToken,
    },
  } = useContext(ReactReduxContext);

  const fetchObras = async url => {
    setIsLoading(true);
    const data = await jwtFetchData(
      url,
      `${process.env.REACT_APP_INBLOCK_BASE_URL}auth/token/refresh/`,
      inblockCurrentUserAccessToken,
      inblockCurrentUserRefreshToken,
      dispatch,
      setInblockCurrentUser
    );

    setObras(data.results);
    setFinalPage(Math.ceil(data.count / 10));
    setIsLoading(false);
  };

  const changePage = num => {
    const newCurrentPage = currentPage + num;

    fetchObras(
      `${process.env.REACT_APP_INBLOCK_BASE_URL}obras/?client=${id}&search=${
        busquedaAplicada ? busquedaAplicada : ""
      }&page=${newCurrentPage}`
    );

    setCurrentPage(newCurrentPage);
  };

  return (
    <Container style={{ maxWidth: "100%", maxHeight: "100%" }}>
      <Row className="text-center mb-3">
        {pathname.includes("pedido") ? (
          <h2>Seleccione obra</h2>
        ) : (
          <h2>Obras de {data.name}</h2>
        )}
      </Row>
      <div className="d-flex justify-content-end mb-3">   
      <Button
        variant="primary"
        onClick={() => {
          history.push(`/inblock/logistica/agregar-obra/${id}`);
        }}
      >
        Agregar Obra
      </Button>
      </div> 
      <Row className="mb-3 text-center">
      {/* <div className="table-responsive p-0">
      <table className="table table-striped table-hover table-bordered text-center">
        <thead>
          <tr>
            <th>ID</th>
            <th>Nombre</th>
            <th>Residente</th>
          </tr>
        </thead>
        <tbody className="text-reset">
          {data.obras.map((obra) => (
            <tr key={obra.id}>
              <td>
                <Link to={`/inblock/logistica/obra/${obra.id}`}>{obra.id}</Link>
              </td>
              <td>
                <Link to={`/inblock/logistica/obra/${obra.id}`}>
                  {obra.name}
                </Link>
              </td>
              <td>
                <Link to={`/inblock/logistica/obra/${obra.id}`}>
                  {obra.resident}
                </Link>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div> */}
      </Row>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <Row>
          <InblockTablaObrasDeCliente obras={data.obras}  idCliente={data.id}/>
          <BotonesPaginacionTablas
            currentPage={currentPage}
            finalPage={finalPage}
            changePage={changePage}
          />
        </Row>
      )}
    </Container>
  );
};
