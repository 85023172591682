import React, { useContext, useEffect } from "react";

/* import { ReactReduxContext } from "../../context/reactReduxContext"; */
import { useIsDesktop } from "../../../hooks/useIsDesktop";
/* import { setUserRole } from "../../redux/user/userSlice";
import { userTypeLinks } from "../../utils/navigationLinks";
import { fetchData } from "../../utils/fetchData"; */

import { MainSectionCover } from "../../../components/main-section-cover/MainSectionCover";
import { MobileMenuButtons } from "../../../components/mobile-menu-buttons/MobileMenuButtons";
import { ErrorBoundary } from "../../../components/error-boundary/ErrorBoundary";

/* import "./HomePage.styles.scss"; */
import { inblockLinks } from "../../../utils/inblockNavigationLinks";
import { InblockHeader } from "../../../components/inblock/inblock-header/InblockHeader";
import { InblockSidebar } from "../../../components/inblock/inblock-sidebar/InblockSidebar";

import styles from "./InblockHomePage.module.scss";
import { InblockErrorBoundary } from "../../../components/inblock/inblock-error-boundary/InblockErrorBondary";

export const InblockHomePage = () => {
  /* const { authtoken, dispatch, setCurrentUser, userRol } =
    useContext(ReactReduxContext); */

  const isDesktop = useIsDesktop();

  /*  useEffect(() => {
    (async () => {
      const fetchedData = await fetchData(
        process.env.REACT_APP_API_CONCRECO_BACKEND_URL + "/api/users/my_role/",
        authtoken,
        dispatch,
        setCurrentUser
      );

      dispatch(setUserRole(fetchedData.my_role));
    })();
  }, []); */

  return (
    <div className="homepage-container">
      {isDesktop && (
        <div className="sidebar-maincontent-container">
          <InblockSidebar navigationLinks={inblockLinks} />
        </div>
      )}
      <div className="header-content-container">
        <InblockHeader title="Bienvenido" />
        <div className={`${isDesktop ? "main-content" : "mobile-content"}`}>
          <InblockErrorBoundary>
            {isDesktop ? (
              <MainSectionCover text="Bienvenido" empresa="inblock" />
            ) : (
              <div className={styles.svgContainer}>
                <div className={styles.mobileMainContent}>
                  <div>
                    {inblockLinks &&
                      inblockLinks.map(userLink => (
                        <MobileMenuButtons
                          key={userLink.id}
                          userLinkObj={userLink}
                          app="inblock"
                        />
                      ))}
                  </div>
                </div>
              </div>
            )}
          </InblockErrorBoundary>
        </div>
      </div>
    </div>
  );
};
