import React, { useContext, useEffect, useRef, useState } from "react";
import { Link, useLocation, useHistory, useParams, } from "react-router-dom";
/* import { DeleteClientButton } from "../delete-client-button/DeleteClientButton"; */
import { ReactReduxContext } from "../../../context/reactReduxContext";
import { ReactComponent as EditarLogo } from "../../../assets/svg/iconoEditar.svg";
import { ModalRedirect } from "../../modal-redirect/ModalRedirect";
export const InblockDetallesPedido = ({ detallesPedido, urlId }) => {
  const { pathname } = useLocation();

  const [form, setForm] = useState({
    payment_status: detallesPedido.payment_status,
    is_active: detallesPedido.is_active === "Desactivado" ? false : true,
  });
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const {
    dispatch,
    inblock: {
      inblockCurrentUserAccessToken,
      inblockCurrentUserRefreshToken,
      setInblockCurrentUser,
      
    },
  } = useContext(ReactReduxContext);
  // console.log(inblockUserRol)
  const history = useHistory();
  const { id } = useParams();
  const nuevoComprobantePagoRef = useRef();
  const actualizarEstatusBtnRef = useRef();
  const statusPedidoRef = useRef();

  useEffect(() => {
    if (pathname.includes("logistica")) {
      if (detallesPedido.is_active === true) {
        
          statusPedidoRef.current.checked = true;
        
      }
    }
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;

    actualizarEstatusBtnRef.current.disabled = false;

    if (name === "is_active") {
      setForm({
        ...form,
        is_active: e.target.checked,
      });

      return;
    }

    setForm({
      ...form,
      [name]: value,
    });
  };

  const handleFileChange = () => {
    actualizarEstatusBtnRef.current.disabled = false;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const is_active = form.is_active ? "Activado" : "Desactivado";
    const payment_status = form.payment_status;

    // let formData = new FormData();

    // formData.append("is_active", is_active);
    // formData.append("payment_status", payment_status);

    // if (nuevoComprobantePagoRef.current.files[0]) {
    //   formData.append(
    //     "payment_proof",
    //     nuevoComprobantePagoRef.current.files[0]
    //   );
    // }

    const formulario ={
      is_active:form.is_active,
      payment_status:form.payment_status,
      payment_proof: form.payment_proof,
    }

    let data = await fetch(
      `${process.env.REACT_APP_INBLOCK_BASE_URL}pedidos/${id}`,
            {
              method: "PATCH",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${inblockCurrentUserAccessToken}`,
              },
              body: JSON.stringify(formulario),
            }
          );

    let json = await data.json();

    if (json.expired) {
      dispatch(
        setInblockCurrentUser({
          access: json.access,
          refresh: inblockCurrentUserRefreshToken,
        })
      );

      data = await fetch(
        `${process.env.REACT_APP_INBLOCK_BASE_URL}pedidos/${id}`,
            {
              method: "PATCH",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${inblockCurrentUserAccessToken}`,
              },
              body: JSON.stringify(formulario),
            }
          );

    let json = await data.json();
    }

    if (data.status === 201 || data.status === 200) {
      setShowConfirmModal(true);
    }
  };

  return (
    <div className="container">
      <ModalRedirect
        showConfirmModal={showConfirmModal}
        text="Se ha actualizado correctamente el pedido."
        link="/inblock/logistica/pedidos"
      />
      {detallesPedido.id ? (
        <>
          <div className="d-flex justify-content-between align-items-center mb-3">
            {/* <div style={{ width: "40px" }} className="invisible">
              .
            </div> */}
            <div className="text-center">
              <h2>{detallesPedido.client.name}</h2>
            </div>
            <div>
              <div className="d-flex  flex-column align-items-center">
                {detallesPedido.id &&
                  pathname.includes("logistica") &&
                  !pathname.includes("realizar-pedido") && (
                    <Link
                      to={`/inblock/logistica/editar-pedido/${detallesPedido.id}`}
                    >
                      <div style={{ width: "40px" }}>
                        <EditarLogo />
                      </div>
                    </Link>
                  )}
                {detallesPedido.id &&
                  pathname.includes("logistica") &&
                  pathname.includes("realizar-pedido") && (
                    <Link
                      to={`/inblock/logistica/editar-cliente/${detallesPedido.id}/realizar-pedido`}
                    >
                      <div style={{ width: "40px" }}>
                        <EditarLogo />
                      </div>
                    </Link>
                  )}
                {detallesPedido.id && pathname.includes("comercializacion") && (
                  <Link
                    to={`/inblock/comercializacion/editar-cliente/${detallesPedido.id}`}
                  >
                    <div style={{ width: "40px" }}>
                      <EditarLogo />
                    </div>
                  </Link>
                )}
                {/* {detallesCliente.id && !pathname.includes("pedido") && (
                  <DeleteClientButton />
                )} */}
              </div>
            </div>
          </div>

          <div className="row">
            <ul className="list-group">
              <li className="list-group-item">
                <strong>Id: </strong> {detallesPedido.id}
              </li>
              <li className="list-group-item">
                <strong>Fecha de entrega:</strong> {detallesPedido.delibery_date}
              </li>
              <li className="list-group-item">
                <strong>Ciudad de entrega: </strong> {detallesPedido.township}
              </li>
              <li className="list-group-item">
                <strong>Hora de entrega: </strong> {detallesPedido.delibery_hr}
              </li>
              <li className="list-group-item">
                <strong>Obra:</strong> {detallesPedido.construction_site.name}
              </li>
              <li className="list-group-item">
                <strong>Material:</strong> {detallesPedido.material}
              </li>
              <li className="list-group-item">
                <strong>Color del material:</strong> {detallesPedido.material_color}
              </li>
              <li className="list-group-item">
                <strong>Medidas del material:</strong> {detallesPedido.material_measures}
              </li>
              <li className="list-group-item">
                <strong>Piezas:</strong> {detallesPedido.pieces}
              </li>
              <li className="list-group-item">
                <strong>Uso del material:</strong> {detallesPedido.material_use}
              </li>
              {/* <li className="list-group-item">
                <strong>Tarimas:</strong> {detallesPedido.platforms}
              </li> */}
              <li className="list-group-item">
                <strong>Precio neto:</strong> $ {detallesPedido.net_price}
              </li>
              <li className="list-group-item">
                <strong>Pago:</strong>{detallesPedido.payment}
              </li>
              <li className="list-group-item">
                <strong>Estatus de pago:</strong>{detallesPedido.payment_status}
              </li>
              <li className="list-group-item">
                <strong>Protocolo de Acceso:</strong>{detallesPedido.access_protocol}
              </li>
              

              
              <li className="list-group-item">
                <strong>Fecha de creación:</strong> {detallesPedido.created_at.slice(0, 10)}{" "}
            {detallesPedido.created_at.slice(11, 16)}
              </li>

              <li className="list-group-item">
            <strong>Comprobante de pago: </strong>
            {detallesPedido.payment_proof ? (
              <a
                target="_blank"
                rel="noreferrer"
                href={detallesPedido.payment_proof}
              >
                Imagen o Archivo
              </a>
            ) : (
              "No hay comprobante de pago"
            )}
          </li>
            </ul>
          </div>
          {pathname.includes("logistica") && (
        <div className="row my-3">
          <form onSubmit={handleSubmit}>
            <div className="d-flex flex-column flex-md-row justify-content-md-between align-items-md-center">
              <div className="mb-2">
                <label htmlFor="pago" className="form-label">
                  Estatus de pago
                </label>
                <select
                  id="pago"
                  name="payment_status"
                  onChange={handleChange}
                  value={form.payment_status}
                  className="form-select"
                  required
                >
                  <option value="Por pagar">Por pagar</option>
                  <option value="Pagado">Pagado</option>
                  <option value="Crédito">Crédito</option>
                </select>
              </div>

              <div className="mb-2">
                <label htmlFor="payment_proof" className="form-label">
                  Comprobante de pago
                </label>
                <input
                  type="file"
                  id="payment_proof"
                  className="form-control"
                  ref={nuevoComprobantePagoRef}
                  onChange={handleFileChange}
                />
              </div>

              
                <div className="form-check form-switch my-3">
                  <label
                    className="form-check-label"
                    htmlFor="flexSwitchCheckDefault"
                  >
                    Activo
                  </label>
                  <input
                    name="is_active"
                    onChange={handleChange}
                    type="checkbox"
                    role="switch"
                    id="flexSwitchCheckDefault"
                    className="form-check-input"
                    ref={statusPedidoRef}
                  />
                </div>
              
            </div>

            <input
              type="submit"
              value="Actualizar Estatus"
              className="btn btn-success"
              ref={actualizarEstatusBtnRef}
              disabled
            />
          </form>
        </div>
      )}
          





          <div className="d-flex justify-content-end mt-2 ">
            

            {pathname.includes("cotizacion") && (
              <Link
                to={`/concreco/comercializacion/cliente/${detallesPedido.id}/obras/realizar-cotizacion`}
                className="btn  mb-2"
                style={{ backgroundColor: "#00C08B", color: "white" }}
              >
                Continuar
              </Link>
            )}

            
            
          </div>




        </>
      ) : (
        <p>No existe cliente #{urlId} </p>
      )}
    </div>
  );
};