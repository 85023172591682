import React from "react";
import { Link, useLocation } from "react-router-dom";
/* import { DeleteClientButton } from "../delete-client-button/DeleteClientButton"; */

import { ReactComponent as EditarLogo } from "../../../assets/svg/iconoEditar.svg";

export const InblockDetalleCondicion = ({ detalleCondicion, urlId }) => {
  const { pathname } = useLocation();

  return (
    <div className="container">
      {detalleCondicion.id ? (
        <>
          <div className="d-flex justify-content-between align-items-center mb-3">
            <div style={{ width: "40px" }} className="invisible">
              .
            </div>
            <div className="text-center">
              <h2>Condición de Venta {detalleCondicion.id}</h2>
            </div>
            <div>
              <div className="d-flex  flex-column align-items-center">
                {detalleCondicion.id &&
                  pathname.includes("logistica") &&
                  !pathname.includes("realizar-pedido") && (
                    <Link
                      to={`/inblock/logistica/editar-cliente/${detalleCondicion.id}`}
                    >
                      <div style={{ width: "40px" }}>
                        <EditarLogo />
                      </div>
                    </Link>
                  )}
                {detalleCondicion.id &&
                  pathname.includes("logistica") &&
                  pathname.includes("realizar-pedido") && (
                    <Link
                      to={`/inblock/logistica/editar-cliente/${detalleCondicion.id}/realizar-pedido`}
                    >
                      <div style={{ width: "40px" }}>
                        <EditarLogo />
                      </div>
                    </Link>
                  )}
                {detalleCondicion.id && pathname.includes("comercializacion") && (
                  <Link
                    to={`/inblock/comercializacion/editar-cliente/${detalleCondicion.id}`}
                  >
                    <div style={{ width: "40px" }}>
                      <EditarLogo />
                    </div>
                  </Link>
                )}
                {/* {detalleCondicion.id && !pathname.includes("pedido") && (
                  <DeleteClientButton />
                )} */}
              </div>
            </div>
          </div>

          <div className="row">
            <ul className="list-group">
              <li className="list-group-item">
                <strong>Id: </strong> {detalleCondicion.id} 
              </li>
              <li className="list-group-item">
                <strong>F/G: </strong> 
                {detalleCondicion.payment_fiscal ? "F" : "G"}
              </li>
              <li className="list-group-item">
                <strong>CFDI: </strong> {detalleCondicion.cfdi}
              </li>
              <li className="list-group-item">
                <strong>Método de Pago:</strong> {detalleCondicion.payment_method}
              </li>
              <li className="list-group-item">
                <strong>Forma de Pago:</strong> {detalleCondicion.payment_form}
              </li>
              {/* <li className="list-group-item">
                <strong>Pago:</strong> {detalleCondicion.payment}
              </li> */}
              <li className="list-group-item">
                <strong>Tipo de venta:</strong> {detalleCondicion.sale_type}
              </li>
              <li className="list-group-item">
                <strong>Tipo de Facturación:</strong> {detalleCondicion.billing_type}
              </li>

              <li className="list-group-item">
                <strong>Fecha de creación:</strong>{" "}
                {detalleCondicion.created_at.slice(0, 10)}
              </li>
            </ul>
          </div>

          <div className="d-flex justify-content-end mt-2 ">
            <Link
              to={`/inblock/logistica/cliente/${detalleCondicion.id}/obras`}
              className="btn btn-secondary mb-2"
              style={{ /* backgroundColor: "#00C08B", */ color: "white" }}
            >
              Ver Obras
            </Link>
          </div>

          {/* <div className="d-flex justify-content-end mt-2 ">
            {pathname.includes("pedido") && (
              <Link
                to={`/concreco/logistica/cliente/${detalleCondicion.id}/obras/realizar-pedido`}
                className="btn  mb-2"
                style={{ backgroundColor: "#00C08B", color: "white" }}
              >
                Continuar
              </Link>
            )}

            {pathname.includes("cotizacion") && (
              <Link
                to={`/concreco/comercializacion/cliente/${detalleCondicion.id}/obras/realizar-cotizacion`}
                className="btn  mb-2"
                style={{ backgroundColor: "#00C08B", color: "white" }}
              >
                Continuar
              </Link>
            )}

            {pathname.includes("logistica") && !pathname.includes("pedido") && (
              <Link
                to={`/concreco/logistica/cliente/${detalleCondicion.id}/obras`}
                className="btn mb-2"
                style={{ backgroundColor: "#00C08B", color: "white" }}
              >
                Ver Obras
              </Link>
            )}
            {pathname.includes("comercializacion") &&
              !pathname.includes("cotizacion") && (
                <Link
                  to={`/concreco/comercializacion/cliente/${detalleCondicion.id}/obras`}
                  className="btn mb-2"
                  style={{ backgroundColor: "#00C08B", color: "white" }}
                >
                  Ver Obras
                </Link>
              )}
          </div> */}
        </>
      ) : (
        <p>No existe cliente #{urlId} </p>
      )}
    </div>
  );
};