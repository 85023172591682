import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
/* import { useFetchAndLoading } from "../../hooks/useFetchAndLoading"; */

import { LoadingSpinner } from "../../../components/loading-spinner/LoadingSpinner";
import { useJwtFetchAndLoading } from "../../../hooks/useJwtFetchAndLoading";
import { InblockFormAgregarObra } from "../../../components/inblock/inblock-form-agregar-obra/InblockFormAgregarObra";


export const InblockEditarObraPage = () => {
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const { idObra, idCondicion } = useParams();

  /* const { data, isLoading } = useJwtFetchAndLoading(
    `${process.env.REACT_APP_INBLOCK_BASE_URL}clientes/choices/`
  ); */

  useEffect(() => {
    (async () => {
      const res = await fetch("clientChoices.json");
      const json = await res.json();

      setData(json);
      setIsLoading(false);
    })();
  }, []);

  return (
    <>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <InblockFormAgregarObra   formToEdit={true}   />
      )}
    </>
  );
};