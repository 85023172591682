import React, { useContext, useState } from "react";
import { Link, useLocation, useParams, } from "react-router-dom";
import Table from "react-bootstrap/Table";
import { ModalRedirect } from "../../modal-redirect/ModalRedirect";

import { ReactReduxContext } from "../../../context/reactReduxContext";
import { setCurrentUser } from "../../../redux/user/userSlice";

import { SuccessModal } from "../../success-modal/SuccessModal";

export const InblockTablaViajes = ({ viajes, setViajes }) => {
  const [show, setShow] = useState(false);
  const [form, setForm] = useState({});
  const { pathname } = useLocation();
  const { id } = useParams();
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  
  const {
    dispatch,
    inblock: {
      inblockCurrentUserAccessToken,
      inblockCurrentUserRefreshToken,
      setInblockCurrentUser,
      
    },
  } = useContext(ReactReduxContext);

  const handleClose = () => setShow(false);

  

//   const handleClick = async (e, viaje) => {
//     try {
//       e.target.classList.add("d-none");

//       e.target.nextSibling.classList.remove("d-none");

//       // let formData = new FormData();

//       let is_active="ww";

//       if (viaje.is_active === false) {
//         is_active = true;
//       }

//       if (viaje.is_active === true) {
//         is_active = false;
//       }
//       console.log(is_active)

//       const formulario ={
//         is_active:is_active,
        
//       }

//       let data = await fetch(
//         `${process.env.REACT_APP_INBLOCK_BASE_URL}viajes/${viaje.id}`,
//         {
//           method: "PATCH",
//           headers: {
//             "Content-Type": "application/json",
//             Authorization: `Bearer ${inblockCurrentUserAccessToken}`,
//           },
//           body: JSON.stringify(formulario),
//         }
//       );

//       let json = await data.json();

//       if (json.expired) {
//         dispatch(setCurrentUser({ token: json.token }));

//         data = await fetch(
//           `${process.env.REACT_APP_INBLOCK_BASE_URL}viajes/${viaje.id}`,
//           {
//             method: "PATCH",
//             headers: {
//               "Content-Type": "application/json",
//               Authorization: `Bearer ${inblockCurrentUserAccessToken}`,
//             },
//             body: JSON.stringify(formulario),
//           }
//         );

//         json = await data.json();
//       }

//       if (data.status === 201 || data.status === 200) {
//         setShow(true);
        

//         setPedidos((prevState) => {
//           const arr = [...prevState];

//           const arrElementsStringifed = arr.map((el) => JSON.stringify(el));

//           const idx = arrElementsStringifed.indexOf(JSON.stringify(viaje));

//           arr.splice(idx, 1, json);

//           return arr;
//         });
//         console.log("bien")
//       }
//     } catch (error) {
//       console.log(error);
//       alert(error);
//     } finally {
//       e.target.nextSibling.classList.add("d-none");
//       e.target.classList.remove("d-none");
//     }
//   };


  return (
    <>
      <SuccessModal
        show={show}
        handleClose={handleClose}
        title="viaje actualizado"
      />
      <div className="table-responsive p-0">
        <Table striped bordered hover className="text-center">
          <thead>
            <tr>
              <th>Id</th>
              <th>viaje</th>
              <th>Conductor</th>
              <th>Tarimas</th>
              <th>Unidad de Entrega</th>

             
            </tr>
          </thead>
          <tbody>
            {viajes.map((viaje) => (
              <tr
                key={viaje.id}
                // className={`${
                //   pathname.includes("logistica")
                //     ? userRol === "Administracion"
                //       ? viaje.is_active === "Activado"
                //         ? "table-success"
                //         : "table-danger"
                //       : ""
                //     : pathname.includes("produccion")
                //     ? userRol === "Operador"
                //       ? viaje.reporte_operador.salida_planta &&
                //         (!viaje.reporte_operador.llegada_obra ||
                //           !viaje.reporte_operador.inicio_bombeo ||
                //           !viaje.reporte_operador.fin_bombeo ||
                //           !viaje.reporte_operador.incidencia)
                //         ? "table-warning"
                //         : viaje.reporte_operador.causa_retraso
                //         ? "table-success"
                //         : "table-danger"
                //       : viaje.is_active === "Activado"
                //       ? "table-success"
                //       : "table-danger"
                //     : ""
                // }`}
              >
                <td>
                  <Link
                    to={
                      pathname.includes("logistica")
                        ? `/inblock/logistica/asignar-pedido/${viaje.id}`
                        : pathname.includes("produccion")
                        ? `/inblock/produccion/viaje/${viaje.id}`
                        : pathname.includes("dashboard") &&
                          `/inblock/dashboard/viaje/${viaje.id}`
                    }
                  >
                    {viaje.id}
                  </Link>
                </td>
                <td>
                  <Link
                    to={
                      pathname.includes("logistica")
                        ? `/inblock/logistica/asignar-pedido/${viaje.id}`
                        : pathname.includes("produccion")
                        ? `/inblock/produccion/viaje/${viaje.id}`
                        : pathname.includes("dashboard") &&
                          `/inblock/dashboard/viaje/${viaje.id}`
                    }
                  >
                   
                    
                    
                    {viaje.orders.id}
                  </Link>
                </td>
                <td>
                  <Link
                    to={
                      pathname.includes("logistica")
                        ? `/inblock/logistica/asignar-pedido/${viaje.id}`
                        : pathname.includes("produccion")
                        ? `/inblock/produccion/viaje/${viaje.id}`
                        : pathname.includes("dashboard") &&
                          `/inblock/dashboard/viaje/${viaje.id}`
                    }
                  >
                    {viaje.driver}
                  </Link>
                </td>
                <td>
                  <Link
                    to={
                      pathname.includes("logistica")
                        ? `/inblock/logistica/asignar-pedido/${viaje.id}`
                        : pathname.includes("produccion")
                        ? `/inblock/produccion/viaje/${viaje.id}`
                        : pathname.includes("dashboard") &&
                          `/inblock/dashboard/viaje/${viaje.id}`
                    }
                  >
                    {viaje.platforms_quantity}
                  </Link>
                </td>
                <td>
                  <Link
                    to={
                      pathname.includes("logistica")
                        ? `/inblock/logistica/asignar-pedido/${viaje.id}`
                        : pathname.includes("produccion")
                        ? `/inblock/produccion/viaje/${viaje.id}`
                        : pathname.includes("dashboard") &&
                          `/inblock/dashboard/viaje/${viaje.id}`
                    }
                  >
                    {viaje.delibery_unit}
                  </Link>
                </td>
                
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    </>
  );
};