import React from "react";
import { Link, useLocation } from "react-router-dom";

export const InblockTablaCondicionesDeCliente = ({ condiciones, idObra }) => {
  const { pathname } = useLocation();
  return (
    <div className="table-responsive p-0">
      <table className="table table-striped table-hover table-bordered text-center">
        <thead>
          <tr>
            <th>ID</th>
            <th>F/G</th>
            <th>CFDI</th>
            <th>Método de Pago</th>
            <th>Forma de Pago</th>
            {/* <th>Pago</th> */}
            <th>Tipo de venta</th>
            <th>Tipo de Facturación</th>

          </tr>
        </thead>
        <tbody className="text-reset">
          {condiciones.map((condicion) => (
            <tr key={condicion.id}>
              <td>
              {pathname.includes("pedido") && (
                <Link to={`/inblock/logistica/obra/${idObra}/condicion-venta/${condicion.id}/form-pedido`}>{condicion.id}</Link>
                )}
              {pathname.includes("logistica") && !pathname.includes("pedido") && (
                <Link to={`/inblock/logistica/cliente/${condicion.client_id}/condicion-venta/${condicion.id}`}>{condicion.id}</Link>
                )}
                </td>
              <td>
              {pathname.includes("pedido") && (
                <Link to={`/inblock/logistica/obra/${idObra}/condicion-venta/${condicion.id}/form-pedido`}>{condicion.payment_fiscal ? "F" : "G"}</Link>
                )}
              {pathname.includes("logistica") && !pathname.includes("pedido") && (
                <Link to={`/inblock/logistica/cliente/${condicion.client_id}/condicion-venta/${condicion.id}`}>{condicion.payment_fiscal ? "F" : "G"}</Link>
                )}
                
              </td>
              <td>
              {pathname.includes("pedido") && (
                <Link to={`/inblock/logistica/obra/${idObra}/condicion-venta/${condicion.id}/form-pedido`}>
                {condicion.cfdi}
              </Link>
                )}
                
              {pathname.includes("logistica") && !pathname.includes("pedido") && (
                <Link to={`/inblock/logistica/cliente/${condicion.client_id}/condicion-venta/${condicion.id}`}>
                {condicion.cfdi}
              </Link>
                )}
                
              </td>
              <td>
              {pathname.includes("pedido") && (
                <Link to={`/inblock/logistica/obra/${idObra}/condicion-venta/${condicion.id}/form-pedido`}>
                {condicion.payment_method}
              </Link>
                )}
              {pathname.includes("logistica") && !pathname.includes("pedido") && (
                <Link to={`/inblock/logistica/cliente/${condicion.client_id}/condicion-venta/${condicion.id}`}>
                {condicion.payment_method}
              </Link>
                )}     
              </td>
              <td>
              {pathname.includes("pedido") && (
                <Link to={`/inblock/logistica/obra/${idObra}/condicion-venta/${condicion.id}/form-pedido`}>
                {condicion.payment_form}
              </Link>
                )}
              {pathname.includes("logistica") && !pathname.includes("pedido") && (
                <Link to={`/inblock/logistica/cliente/${condicion.client_id}/condicion-venta/${condicion.id}`}>
                {condicion.payment_form}
              </Link>
                )}
              </td>
              {/* <td>
              {pathname.includes("pedido") && (
                <Link to={`/inblock/logistica/obra/${idObra}/condicion-venta/${condicion.id}/form-pedido`}>
                {condicion.payment}
              </Link>
                )}
              {pathname.includes("logistica") && !pathname.includes("pedido") && (
                <Link to={`/inblock/logistica/cliente/${condicion.client_id}/condicion-venta/${condicion.id}`}>
                {condicion.payment}
              </Link>
                )}
               
              </td> */}
              <td>
              {pathname.includes("pedido") && (
                <Link to={`/inblock/logistica/obra/${idObra}/condicion-venta/${condicion.id}/form-pedido`}>
                {condicion.sale_type}
              </Link>
                )}
              {pathname.includes("logistica") && !pathname.includes("pedido") && (
                <Link to={`/inblock/logistica/cliente/${condicion.client_id}/condicion-venta/${condicion.id}`}>
                {condicion.sale_type}
              </Link>
                )}
          
              </td>
              <td>
              {pathname.includes("pedido") && (
                <Link to={`/inblock/logistica/obra/${idObra}/condicion-venta/${condicion.id}/form-pedido`}>
                {condicion.billing_type}
              </Link>
                )}
              {pathname.includes("logistica") && !pathname.includes("pedido") && (
                <Link to={`/inblock/logistica/cliente/${condicion.client_id}/condicion-venta/${condicion.id}`}>
                {condicion.billing_type}
              </Link>
                )}
                
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};