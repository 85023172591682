import React from "react";
import { Link, useLocation } from "react-router-dom";
/* import { DeleteClientButton } from "../delete-client-button/DeleteClientButton"; */

import { ReactComponent as EditarLogo } from "../../../assets/svg/iconoEditar.svg";

export const InblockDetalleObra = ({ detalleObra, urlId }) => {
  const { pathname } = useLocation();

  return (
    <div className="container">
      {detalleObra.id ? (
        <>
          <div className="d-flex justify-content-between align-items-center mb-3">
            <div style={{ width: "40px" }} className="invisible">
              .
            </div>
            <div className="text-center">
              <h2>{detalleObra.name}</h2>
            </div>
            <div>
              <div className="d-flex  flex-column align-items-center">
                {detalleObra.id &&
                  pathname.includes("logistica") &&
                  !pathname.includes("realizar-pedido") && (
                    <Link
                      to={`/inblock/logistica/editar-obra/${detalleObra.id}`}
                    >
                      <div style={{ width: "40px" }}>
                        <EditarLogo />
                      </div>
                    </Link>
                  )}
                {detalleObra.id &&
                  pathname.includes("logistica") &&
                  pathname.includes("realizar-pedido") && (
                    <Link
                      to={`/inblock/logistica/editar-cliente/${detalleObra.id}/realizar-pedido`}
                    >
                      <div style={{ width: "40px" }}>
                        <EditarLogo />
                      </div>
                    </Link>
                  )}
                {detalleObra.id && pathname.includes("comercializacion") && (
                  <Link
                    to={`/inblock/comercializacion/editar-cliente/${detalleObra.id}`}
                  >
                    <div style={{ width: "40px" }}>
                      <EditarLogo />
                    </div>
                  </Link>
                )}
                {/* {detalleObra.id && !pathname.includes("pedido") && (
                  <DeleteClientButton />
                )} */}
              </div>
            </div>
          </div>

          <div className="row">
            <ul className="list-group">
              <li className="list-group-item">
                <strong>Id: </strong> {detalleObra.id}
              </li>
              <li className="list-group-item">
                <strong>Nombre del cliente:</strong> {detalleObra.client.name}
              </li>
              <li className="list-group-item">
                <strong>Residente: </strong> {detalleObra.resident}
              </li>
              <li className="list-group-item">
                <strong>Ciudad:</strong> {detalleObra.destination_city}
              </li>
              <li className="list-group-item">
                <strong>Dirección:</strong> {detalleObra.adress}
              </li>
              <li className="list-group-item">
                <strong>Referencias:</strong> {detalleObra.references}
              </li>
              <li className="list-group-item">
                <strong>Teléfono:</strong> {detalleObra.phone}
              </li>
              <li className="list-group-item">
                <strong>Email:</strong> {detalleObra.email}
              </li>
              <li className="list-group-item">
                <strong>Link Obra:</strong> {detalleObra.postal_code}
              </li>
              <li className="list-group-item">
                <strong>Fecha de creación:</strong>{" "}
                {detalleObra.created_at.slice(0, 10)}
              </li>
            </ul>
          </div>

          <div className="d-flex justify-content-end mt-2 ">
            {pathname.includes("pedido") && (
              <Link
                to={`/inblock/logistica/obra/${detalleObra.id}/condicion-venta/${detalleObra.client.id}/realizar-pedido`}
                className="btn  mb-2"
                style={{ backgroundColor: "#00C08B", color: "white" }}
              >
                Continuar
              </Link>
            )}

            {/* {pathname.includes("cotizacion") && (
              <Link
                to={`/concreco/comercializacion/cliente/${detallesCliente.id}/obras/realizar-cotizacion`}
                className="btn  mb-2"
                style={{ backgroundColor: "#00C08B", color: "white" }}
              >
                Continuar
              </Link>
            )}

            {pathname.includes("comercializacion") &&
              !pathname.includes("cotizacion") && (
                <Link
                  to={`/concreco/comercializacion/cliente/${detallesCliente.id}/obras`}
                  className="btn mb-2"
                  style={{ backgroundColor: "#00C08B", color: "white" }}
                >
                  Ver Obras
                </Link>
              )} */}
          </div>



          {/* <div className="d-flex justify-content-end mt-2 ">
            {pathname.includes("pedido") && (
              <Link
                to={`/concreco/logistica/cliente/${detalleObra.id}/obras/realizar-pedido`}
                className="btn  mb-2"
                style={{ backgroundColor: "#00C08B", color: "white" }}
              >
                Continuar
              </Link>
            )}

            {pathname.includes("cotizacion") && (
              <Link
                to={`/concreco/comercializacion/cliente/${detalleObra.id}/obras/realizar-cotizacion`}
                className="btn  mb-2"
                style={{ backgroundColor: "#00C08B", color: "white" }}
              >
                Continuar
              </Link>
            )}

            {pathname.includes("logistica") && !pathname.includes("pedido") && (
              <Link
                to={`/concreco/logistica/cliente/${detalleObra.id}/obras`}
                className="btn mb-2"
                style={{ backgroundColor: "#00C08B", color: "white" }}
              >
                Ver Obras
              </Link>
            )}
            {pathname.includes("comercializacion") &&
              !pathname.includes("cotizacion") && (
                <Link
                  to={`/concreco/comercializacion/cliente/${detalleObra.id}/obras`}
                  className="btn mb-2"
                  style={{ backgroundColor: "#00C08B", color: "white" }}
                >
                  Ver Obras
                </Link>
              )}
          </div> */}
        </>
      ) : (
        <p>No existe cliente #{urlId} </p>
      )}
    </div>
  );
};
