import React, { useEffect, useState } from "react";

/* import { useJwtFetchAndLoading } from "../../../hooks/useJwtFetchAndLoading"; */
import { InblockFormAgregarCliente } from "../../../components/inblock/inblock-form-agregar-cliente/InblockFormAgregarCliente";
import { LoadingSpinner } from "../../../components/loading-spinner/LoadingSpinner";

export const InblockEditarClientePage = () => {
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  /* const { data, isLoading } = useJwtFetchAndLoading(
    `${process.env.REACT_APP_INBLOCK_BASE_URL}clientes/choices/`
  ); */

  useEffect(() => {
    (async () => {
      const res = await fetch("clientChoices.json");
      const json = await res.json();

      setData(json);
      setIsLoading(false);
    })();
  }, []);

  return (
    <>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <InblockFormAgregarCliente choicesData={data} formToEdit={true} />
      )}
    </>
  );
};
