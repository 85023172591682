
import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { InblockFormAgregarCliente } from "../../../components/inblock/inblock-form-agregar-cliente/InblockFormAgregarCliente";

/* import { useFetchAndLoading } from "../../hooks/useFetchAndLoading"; */
import { jwtFetchData } from "../../../utils/jwtFetchData";

import { LoadingSpinner } from "../../../components/loading-spinner/LoadingSpinner";
import { useJwtFetchAndLoading } from "../../../hooks/useJwtFetchAndLoading";
/* import { FormAgregarCliente } from "../../../components/form-agregar-cliente/FormAgregarCliente"; */
import { InblockFormAgregarViaje } from "../../../components/inblock/inblock-form-agregar-viaje/InblockFormAgregarViaje";
/* import CHOISES from "../../../utils/newClientChoices.json"; */
import { ReactReduxContext } from "../../../context/reactReduxContext";
export const InblockAgregarViajePage = () => {
  // const [data, setData] = useState(null);
  // const [isLoading, setIsLoading] = useState(true);
  const { idPedido } = useParams();
  const [conductores, setConductores] = useState(null);

  // /const { data, isLoading } = useJwtFetchAndLoading(
  //   `${process.env.REACT_APP_INBLOCK_BASE_URL}clientes/choices/`
  // ); 
  const {
    dispatch,
    inblock: {
      setInblockCurrentUser,
      inblockCurrentUserAccessToken,
      inblockCurrentUserRefreshToken,
    },
  } = useContext(ReactReduxContext);

  const { data, isLoading } = useJwtFetchAndLoading(
    `${process.env.REACT_APP_INBLOCK_BASE_URL}pedidos/conductores`,
    
  );

  return (
    <>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <InblockFormAgregarViaje  idPedido={idPedido} conductores={data}  />
      )}
    </>
  );
};