import { Switch, Route, Link, Redirect } from "react-router-dom";

import { NetworkStatus } from "./components/network-status/NetworkStatus";
import { ToastContainer } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";
import "./App.scss";

import Logo from "./assets/grupoeco/svg/Logo.svg";
import LogoConcreco from "./assets/svg/Logo-Blanco-3.svg";
import LogoInblock from "./assets/inblock/svg/Logo-inblock-blanco.svg";
import LogoE from "./assets/svg/logoEcoBlanco.svg";
import Support from "./assets/svg/support-logo2.svg";



import styles from "./Home.module.scss";
import { Concreco } from "./pages/concreco/Concreco";
import { Inblock } from "./pages/inblock/Inblock";
import { Helmet } from "react-helmet";

NetworkStatus();

function App() {
  return (
    <div className={`${styles.homePage} App`}>
      <Helmet
      /* onChangeClientState={(newState, addedTags, removedTags) =>
          console.log(newState, addedTags, removedTags)
        } */
      >
        <title>Grupo ECO</title>
        <link rel="icon" type="image/png" href="favicon.ico" />
        <meta name="description" content="Grupo ECO" />
      </Helmet>
      <ToastContainer autoClose={3000} hideProgressBar />

      <Switch>
        <Route exact path="/">
          <div className={styles.imgContainer}>
            <div className={styles.img}>
              <img src={Logo} alt="Logo" />
            </div>
          </div>

          <div className={styles.logoButtonsContainer}>
            <div className={styles.logoContainer}>
              <Link to="/concreco">
                <img src={LogoConcreco} alt="Logo-concreco" />
              </Link>
            </div>
            <div className={styles.logoContainer}>
              <Link to="/inblock">
                <img src={LogoInblock} alt="Logo-inblock" />
              </Link>
            </div>

            <div className={styles.logoContainer}>
             <a href="https://activos-react-front.vercel.app/#/"><img src={LogoE} alt="Logo-inblock" class="img-activos"  /></a>
               
              
            </div>

            <div className={styles.logoContainer}>
             <a href="http://seilsa.fortiddns.com:8080/sysaid/Login.jsp"  target="_blank"><img src={Support} alt="Logo-inblock" class="img-support" /></a>
               
              
            </div>
          </div>
        </Route>
        <Route path="/concreco">
          <Concreco />
        </Route>
        <Route path="/inblock">
          <Inblock />
        </Route>
        <Route path="*">
          <Redirect to="/" />
        </Route>
      </Switch>
    </div>
  );
}

export default App;
