import React, { useContext, useState } from "react";
import { Link, useLocation, useParams, } from "react-router-dom";
import Table from "react-bootstrap/Table";
import { ModalRedirect } from "../../modal-redirect/ModalRedirect";

import { ReactReduxContext } from "../../../context/reactReduxContext";
import { setCurrentUser } from "../../../redux/user/userSlice";

import { SuccessModal } from "../../success-modal/SuccessModal";

export const InblockTablaPedidos = ({ pedidos, setPedidos }) => {
  const [show, setShow] = useState(false);
  const [form, setForm] = useState({});
  const { pathname } = useLocation();
  const { id } = useParams();
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  
  const {
    dispatch,
    inblock: {
      inblockCurrentUserAccessToken,
      inblockCurrentUserRefreshToken,
      setInblockCurrentUser,
      
    },
  } = useContext(ReactReduxContext);

  const handleClose = () => setShow(false);

  

  const handleClick = async (e, pedido) => {
    try {
      e.target.classList.add("d-none");

      e.target.nextSibling.classList.remove("d-none");

      // let formData = new FormData();

      let is_active="ww";

      if (pedido.is_active === false) {
        is_active = true;
      }

      if (pedido.is_active === true) {
        is_active = false;
      }
      console.log(is_active)

      const formulario ={
        is_active:is_active,
        
      }

      let data = await fetch(
        `${process.env.REACT_APP_INBLOCK_BASE_URL}pedidos/${pedido.id}`,
        {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${inblockCurrentUserAccessToken}`,
          },
          body: JSON.stringify(formulario),
        }
      );

      let json = await data.json();

      if (json.expired) {
        dispatch(setCurrentUser({ token: json.token }));

        data = await fetch(
          `${process.env.REACT_APP_INBLOCK_BASE_URL}pedidos/${pedido.id}`,
          {
            method: "PATCH",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${inblockCurrentUserAccessToken}`,
            },
            body: JSON.stringify(formulario),
          }
        );

        json = await data.json();
      }

      if (data.status === 201 || data.status === 200) {
        setShow(true);
        

        setPedidos((prevState) => {
          const arr = [...prevState];

          const arrElementsStringifed = arr.map((el) => JSON.stringify(el));

          const idx = arrElementsStringifed.indexOf(JSON.stringify(pedido));

          arr.splice(idx, 1, json);

          return arr;
        });
        console.log("bien")
      }
    } catch (error) {
      console.log(error);
      alert(error);
    } finally {
      e.target.nextSibling.classList.add("d-none");
      e.target.classList.remove("d-none");
    }
  };


  return (
    <>
      <SuccessModal
        show={show}
        handleClose={handleClose}
        title="Pedido actualizado"
      />
      <div className="table-responsive p-0">
        <Table striped bordered hover className="text-center">
          <thead>
            <tr>
              <th>Id</th>
              <th>Cliente</th>
              <th>Fecha de entrega</th>
              <th>Horario</th>
              <th>Ciudad</th>
              <th>Piezas</th>
              <th>Uso de material</th>
              <th>Color</th>
              {/* <th>Tarimas</th> */}
              <th>Precio neto</th>
              <th>Tipo de pago</th>
              <th>Estatus de pago</th>

              {/* <th>Obra</th>
              {pathname.includes("logistica") && <th>Estatus de pago</th>}
              {pathname.includes("logistica") &&
                userRol === "Administracion" && <th>Comprobante de pago</th>} */}
              <th>Estatus de pedido</th>
              <th>Fecha de solicitud</th>

              <th>Asesor</th>
            </tr>
          </thead>
          <tbody>
            {pedidos.map((pedido) => (
              <tr
                key={pedido.id}
                className={`${
                  
                    pedido.is_active === true ? "table-success": "table-danger"
                    
                  

                }`}
              >
                <td>
                  <Link
                    to={
                      pathname.includes("pedidos")
                        ? `/inblock/logistica/pedido/${pedido.id}`
                        : pathname.includes("asignar-pedido")
                        ? `/inblock/logistica/pedido/${pedido.id}`
                        : pathname.includes("viajes")
                        ? `/inblock/logistica/crear-viaje/${pedido.id}`
                        : pathname.includes("asignar-pedido")
                        ? `/inblock/logistica/crear-viaje/${pedido.id}`
                        : pathname.includes("dashboard") &&
                          `/inblock/dashboard/pedido/${pedido.id}`
                    }
                  >
                    {pedido.id}
                  </Link>
                </td>
                <td>
                  <Link
                    to={
                      pathname.includes("pedidos")
                        ? `/inblock/logistica/pedido/${pedido.id}`
                        : pathname.includes("viajes")
                        ? `/inblock/logistica/crear-viaje/${pedido.id}`
                        : pathname.includes("dashboard") &&
                          `/inblock/dashboard/pedido/${pedido.id}`
                    }
                  >
                    {pedido.client.name}
                  </Link>
                </td>
                <td>
                  <Link
                    to={
                      pathname.includes("pedidos")
                        ? `/inblock/logistica/pedido/${pedido.id}`
                        : pathname.includes("viajes")
                        ? `/inblock/logistica/crear-viaje/${pedido.id}`
                        : pathname.includes("dashboard") &&
                          `/inblock/dashboard/pedido/${pedido.id}`
                    }
                  >
                    {pedido.delibery_date.slice(0, 10)}
                  </Link>
                </td>
                <td>
                  <Link
                    to={
                      pathname.includes("pedidos")
                        ? `/inblock/logistica/pedido/${pedido.id}`
                        : pathname.includes("viajes")
                        ? `/inblock/logistica/crear-viaje/${pedido.id}`
                        : pathname.includes("dashboard") &&
                          `/inblock/dashboard/pedido/${pedido.id}`
                    }
                  >
                    {pedido.delibery_hr}
                  </Link>
                </td>
                <td>
                  <Link
                    to={
                      pathname.includes("pedidos")
                        ? `/inblock/logistica/pedido/${pedido.id}`
                        : pathname.includes("viajes")
                        ? `/inblock/logistica/crear-viaje/${pedido.id}`
                        : pathname.includes("dashboard") &&
                          `/inblock/dashboard/pedido/${pedido.id}`
                    }
                  >
                    {pedido.township}
                  </Link>
                </td>
                <td>
                  <Link
                    to={
                      pathname.includes("pedidos")
                        ? `/inblock/logistica/pedido/${pedido.id}`
                        : pathname.includes("viajes")
                        ? `/inblock/logistica/crear-viaje/${pedido.id}`
                        : pathname.includes("dashboard") &&
                          `/inblock/dashboard/pedido/${pedido.id}`
                    }
                  >
                    {pedido.pieces}
                  </Link>
                </td>
                <td>
                  <Link
                    to={
                      pathname.includes("pedidos")
                        ? `/inblock/logistica/pedido/${pedido.id}`
                        : pathname.includes("viajes")
                        ? `/inblock/logistica/crear-viaje/${pedido.id}`
                        : pathname.includes("dashboard") &&
                          `/inblock/dashboard/pedido/${pedido.id}`
                    }
                  >
                    {pedido.material_use}
                  </Link>
                </td>
                <td>
                  <Link
                    to={
                      pathname.includes("pedidos")
                        ? `/inblock/logistica/pedido/${pedido.id}`
                        : pathname.includes("viajes")
                        ? `/inblock/logistica/crear-viaje/${pedido.id}`
                        : pathname.includes("dashboard") &&
                          `/inblock/dashboard/pedido/${pedido.id}`
                    }
                  >
                    {pedido.material_color}
                  </Link>
                </td>
                {/* <td>
                  <Link
                    to={
                      pathname.includes("pedidos")
                        ? `/inblock/logistica/pedido/${pedido.id}`
                        : pathname.includes("viajes")
                        ? `/inblock/logistica/crear-viaje/${pedido.id}`
                        : pathname.includes("dashboard") &&
                          `/inblock/dashboard/pedido/${pedido.id}`
                    }
                  >
                    {pedido.platforms}
                  </Link>
                </td> */}
                <td>
                  <Link
                    to={
                      pathname.includes("pedidos")
                        ? `/inblock/logistica/pedido/${pedido.id}`
                        : pathname.includes("viajes")
                        ? `/inblock/logistica/crear-viaje/${pedido.id}`
                        : pathname.includes("dashboard") &&
                          `/inblock/dashboard/pedido/${pedido.id}`
                    }
                  >
                    {"$ "+ pedido.net_price}
                  </Link>
                </td>
                <td>
                  <Link
                    to={
                      pathname.includes("pedidos")
                        ? `/inblock/logistica/pedido/${pedido.id}`
                        : pathname.includes("viajes")
                        ? `/inblock/logistica/crear-viaje/${pedido.id}`
                        : pathname.includes("dashboard") &&
                          `/inblock/dashboard/pedido/${pedido.id}`
                    }
                  >
                    {pedido.payment}
                  </Link>
                </td>
                <td>
                  <Link
                    to={
                      pathname.includes("pedidos")
                        ? `/inblock/logistica/pedido/${pedido.id}`
                        : pathname.includes("viajes")
                        ? `/inblock/logistica/crear-viaje/${pedido.id}`
                        : pathname.includes("dashboard") &&
                          `/inblock/dashboard/pedido/${pedido.id}`
                    }
                  >
                    {pedido.payment_status === "Por pagar" && "PP"}
                    {pedido.payment_status === "Pagado" && "P"}
                    {pedido.payment_status === "Credito" && "C"}
                  </Link>
                </td>
                <td>
                  <Link
                    to={
                      pathname.includes("pedidos")
                        ? `/inblock/logistica/pedido/${pedido.id}`
                        : pathname.includes("viajes")
                        ? `/inblock/logistica/crear-viaje/${pedido.id}`
                        : pathname.includes("dashboard") &&
                          `/inblock/dashboard/pedido/${pedido.id}`
                    }
                  >
                    {pedido.is_active === true && "Activado"}
                    {pedido.is_active === false && "Desactivado"}
                  </Link>

                      
                      <>
                        <button
                          className="btn btn-primary btn-sm"
                          onClick={(e) => {
                            handleClick(e, pedido);
                          }}
                        >
                          {pedido.is_active === true && "Desactivar"}
                          {pedido.is_active === false && "Activar"}
                        </button>

                        <button
                          className="btn btn-primary d-none"
                          type="button"
                          disabled
                        >
                          <span
                            className="spinner-border spinner-border-sm"
                            role="status"
                            aria-hidden="true"
                          ></span>
                          <span className="visually-hidden">Loading...</span>
                        </button>
                      </>
                    
                </td>
                <td>
                  <Link
                    to={
                      pathname.includes("pedidos")
                        ? `/inblock/logistica/pedido/${pedido.id}`
                        : pathname.includes("viajes")
                        ? `/inblock/logistica/crear-viaje/${pedido.id}`
                        : pathname.includes("dashboard") &&
                          `/inblock/dashboard/pedido/${pedido.id}`
                    }
                  >
                    {pedido.created_at.slice(0, 10)}
                  </Link>
                </td>
                <td>
                  <Link
                    to={
                      pathname.includes("pedidos")
                        ? `/inblock/logistica/pedido/${pedido.id}`
                        : pathname.includes("viajes")
                        ? `/inblock/logistica/crear-viaje/${pedido.id}`
                        : pathname.includes("dashboard") &&
                          `/inblock/dashboard/pedido/${pedido.id}`
                    }
                  >
                    {pedido.assessor.name+" "+ pedido.assessor.last_name}
                  </Link>
                </td>
                {/* <td>
                  <Link
                    to={
                      pathname.includes("logistica")
                        ? `/inblock/logistica/pedido/${pedido.id}`
                        : pathname.includes("viajes")
                        ? `/inblock/logistica/crear-viaje/${pedido.id}`
                        : pathname.includes("dashboard") &&
                          `/inblock/dashboard/pedido/${pedido.id}`
                    }
                  >
                    {pedido.fecha_pedido.slice(0, 10)}
                  </Link>
                </td>
                <td>
                  <Link
                    to={
                      pathname.includes("logistica")
                        ? `/inblock/logistica/pedido/${pedido.id}`
                        : pathname.includes("produccion")
                        ? `/inblock/logistica/crear-viaje/${pedido.id}`
                        : pathname.includes("dashboard") &&
                          `/inblock/dashboard/pedido/${pedido.id}`
                    }
                  >
                    {pedido.fecha_pedido.slice(11, 16)}
                  </Link>
                </td>
                <td>
                  <Link
                    to={
                      pathname.includes("logistica")
                        ? `/inblock/logistica/pedido/${pedido.id}`
                        : pathname.includes("produccion")
                        ? `/inblock/logistica/crear-viaje/${pedido.id}`
                        : pathname.includes("dashboard") &&
                          `/inblock/dashboard/pedido/${pedido.id}`
                    }
                  >
                    {pedido.m3}
                  </Link>
                </td>
                <td>
                  <Link
                    to={
                      pathname.includes("logistica")
                        ? `/inblock/logistica/pedido/${pedido.id}`
                        : pathname.includes("produccion")
                        ? `/inblock/logistica/crear-viaje/${pedido.id}`
                        : pathname.includes("dashboard") &&
                          `/inblock/dashboard/pedido/${pedido.id}`
                    }
                  >
                    {pedido.mas_ajuste}
                  </Link>
                </td>
                <td>
                  <Link
                    to={
                      pathname.includes("logistica")
                        ? `/inblock/logistica/pedido/${pedido.id}`
                        : pathname.includes("produccion")
                        ? `/inblock/logistica/crear-viaje/${pedido.id}`
                        : pathname.includes("dashboard") &&
                          `/inblock/dashboard/pedido/${pedido.id}`
                    }
                  >
                    {`${pedido.tipo}${pedido.resistencia}${pedido.edad}${pedido.tma}${pedido.revenimiento}${pedido.forma}`}
                  </Link>
                </td>
                <td>
                  <Link
                    to={
                      pathname.includes("logistica")
                        ? `/inblock/logistica/pedido/${pedido.id}`
                        : pathname.includes("produccion")
                        ? `/inblock/logistica/crear-viaje/${pedido.id}`
                        : pathname.includes("dashboard") &&
                          `/inblock/dashboard/pedido/${pedido.id}`
                    }
                  >
                    {pedido.forma}
                  </Link>
                </td>
                <td>
                  <Link
                    to={
                      pathname.includes("logistica")
                        ? `/inblock/logistica/pedido/${pedido.id}`
                        : pathname.includes("produccion")
                        ? `/inblock/logistica/crear-viaje/${pedido.id}`
                        : pathname.includes("dashboard") &&
                          `/inblock/dashboard/pedido/${pedido.id}`
                    }
                  >
                    {pedido.obra_nombre}
                  </Link>
                </td>

                {pathname.includes("logistica") && (
                  <td>
                    <Link
                      to={
                        pathname.includes("logistica")
                          ? `/inblock/logistica/pedido/${pedido.id}`
                          : pathname.includes("produccion")
                          ? `/inblock/logistica/crear-viaje/${pedido.id}`
                          : pathname.includes("dashboard") &&
                            `/inblock/dashboard/pedido/${pedido.id}`
                      }
                    >
                      {pedido.status_pago === "Por pagar" && "PP"}
                      {pedido.status_pago === "Crédito" && "C"}
                      {pedido.status_pago === "Pagado" && "P"}
                    </Link>
                  </td>
                )}

                {pathname.includes("logistica") &&
                  userRol === "Administracion" && (
                    <td>
                      <a
                        target="_blank"
                        rel="noreferrer"
                        href={pedido.comprobante_pago}
                      >
                        {pedido.comprobante_pago ? "Ver" : "N/A"}
                      </a>
                    </td>
                  )}

                <td>
                  <Link
                    to={
                      pathname.includes("logistica")
                        ? `/inblock/logistica/pedido/${pedido.id}`
                        : pathname.includes("produccion")
                        ? `/inblock/logistica/crear-viaje/${pedido.id}`
                        : pathname.includes("dashboard") &&
                          `/inblock/dashboard/pedido/${pedido.id}`
                    }
                  >
                    {pedido.is_active}
                  </Link>

                  {pathname.includes("logistica") &&
                    userRol === "Administracion" && (
                      <>
                        <button
                          className="btn btn-primary btn-sm"
                          onClick={(e) => {
                            handleClick(e, pedido);
                          }}
                        >
                          {pedido.is_active === "Activado" && "Desactivar"}
                          {pedido.is_active === "Desactivado" && "Activar"}
                        </button>

                        <button
                          className="btn btn-primary d-none"
                          type="button"
                          disabled
                        >
                          <span
                            className="spinner-border spinner-border-sm"
                            role="status"
                            aria-hidden="true"
                          ></span>
                          <span className="visually-hidden">Loading...</span>
                        </button>
                      </>
                    )}
                </td>
                <td>
                  <Link
                    to={
                      pathname.includes("logistica")
                        ? `/inblock/logistica/pedido/${pedido.id}`
                        : pathname.includes("produccion")
                        ? `/inblock/logistica/crear-viaje/${pedido.id}`
                        : pathname.includes("dashboard") &&
                          `/inblock/dashboard/pedido/${pedido.id}`
                    }
                  >
                    {pedido.created_at.slice(0, 10)}
                  </Link>
                </td>
                <td>
                  <Link
                    to={
                      pathname.includes("logistica")
                        ? `/inblock/logistica/pedido/${pedido.id}`
                        : pathname.includes("produccion")
                        ? `/inblock/logistica/crear-viaje/${pedido.id}`
                        : pathname.includes("dashboard") &&
                          `/inblock/dashboard/pedido/${pedido.id}`
                    }
                  >
                    {pedido.planta_nombre === "Planta 01 León" && "P01L"}
                    {pedido.planta_nombre === "Planta 02 Puerto Interior" &&
                      "P02Pi"}
                    {pedido.planta_nombre === "Planta 03 Salida a Lagos" &&
                      "P03SL"}
                    {pedido.planta_nombre === "Planta 04 San Miguel" &&
                      "P04SMA"}
                    {pedido.planta_nombre === "PT San Pancho" && "PT SP"}
                  </Link>
                </td>
                <td>
                  <Link
                    to={
                      pathname.includes("logistica")
                        ? `/inblock/logistica/pedido/${pedido.id}`
                        : pathname.includes("produccion")
                        ? `/inblock/logistica/crear-viaje/${pedido.id}`
                        : pathname.includes("dashboard") &&
                          `/inblock/dashboard/pedido/${pedido.id}`
                    }
                  >
                    {pedido.asesor_comercial}
                  </Link>
                </td> */}
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    </>
  );
};