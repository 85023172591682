import React from "react";
import { Link, useLocation } from "react-router-dom";
/* import { DeleteClientButton } from "../delete-client-button/DeleteClientButton"; */

import { ReactComponent as EditarLogo } from "../../../assets/svg/iconoEditar.svg";

export const InblockDetallesCliente = ({ detallesCliente, urlId }) => {
  const { pathname } = useLocation();

  return (
    <div className="container">
      {detallesCliente.id ? (
        <>
          <div className="d-flex justify-content-between align-items-center mb-3">
            {/* <div style={{ width: "40px" }} className="invisible">
              .
            </div> */}
            <div className="text-center">
              <h2>{detallesCliente.name}</h2>
            </div>
            <div>
              <div className="d-flex  flex-column align-items-center">
                {detallesCliente.id &&
                  pathname.includes("logistica") &&
                  !pathname.includes("realizar-pedido") && (
                    <Link
                      to={`/inblock/logistica/editar-cliente/${detallesCliente.id}`}
                    >
                      <div style={{ width: "40px" }}>
                        <EditarLogo />
                      </div>
                    </Link>
                  )}
                {detallesCliente.id &&
                  pathname.includes("logistica") &&
                  pathname.includes("realizar-pedido") && (
                    <Link
                      to={`/inblock/logistica/editar-cliente/${detallesCliente.id}/realizar-pedido`}
                    >
                      <div style={{ width: "40px" }}>
                        <EditarLogo />
                      </div>
                    </Link>
                  )}
                {detallesCliente.id && pathname.includes("comercializacion") && (
                  <Link
                    to={`/inblock/comercializacion/editar-cliente/${detallesCliente.id}`}
                  >
                    <div style={{ width: "40px" }}>
                      <EditarLogo />
                    </div>
                  </Link>
                )}
                {/* {detallesCliente.id && !pathname.includes("pedido") && (
                  <DeleteClientButton />
                )} */}
              </div>
            </div>
          </div>

          <div className="row">
            <ul className="list-group">
              <li className="list-group-item">
                <strong>Id: </strong> {detallesCliente.id}
              </li>
              <li className="list-group-item">
                <strong>Alias:</strong> {detallesCliente.alias}
              </li>
              <li className="list-group-item">
                <strong>RFC: </strong> {detallesCliente.rfc}
              </li>
              <li className="list-group-item">
                <strong>Pago:</strong>{" "}
                {detallesCliente.payment_fiscal ? "F" : "G"}
              </li>
              <li className="list-group-item">
                <strong>Uso de CFDI:</strong> {detallesCliente.cfdi}
              </li>
              <li className="list-group-item">
                <strong>Método de pago:</strong>{" "}
                {detallesCliente.payment_method}
              </li>
              <li className="list-group-item">
                <strong>Forma de pago:</strong> {detallesCliente.payment_form}
              </li>
              <li className="list-group-item">
                <strong>Correo de facturas:</strong>{" "}
                {detallesCliente.email_invoice}
              </li>
              <li className="list-group-item">
                <strong>Nombre de cuentas por pagar:</strong>{" "}
                {detallesCliente.name_payments_pendings}
              </li>
              <li className="list-group-item">
                <strong>Teléfono de cuentas por pagar:</strong>{" "}
                {detallesCliente.phone_payments_pendings}
              </li>
              <li className="list-group-item">
                <strong>Correo de cuentas por pagar:</strong>{" "}
                {detallesCliente.email_payments_pendings}
              </li>
              <li className="list-group-item">
                <strong>Estado: </strong> {detallesCliente.republic_state}
              </li>
              <li className="list-group-item">
                <strong>Municipio:</strong> {detallesCliente.township}
              </li>
              <li className="list-group-item">
                <strong>Código Postal:</strong> {detallesCliente.postal_code}
              </li>
              <li className="list-group-item">
                <strong>Giro de empresa:</strong> {detallesCliente.company_type}
              </li>
              <li className="list-group-item">
                <strong>Tipo venta:</strong> {"-"}
              </li>
              <li className="list-group-item">
                <strong>Dirección:</strong> {detallesCliente.address}
              </li>
              <li className="list-group-item">
                <strong> Teléfono de oficinas:</strong> {detallesCliente.phone}
              </li>
              <li className="list-group-item">
                <strong> Email:</strong> {detallesCliente.email}
              </li>
              <li className="list-group-item">
                <strong>Sitio Web:</strong> {detallesCliente.website}
              </li>
              <li className="list-group-item">
                <strong>Medio de contacto:</strong>{" "}
                {detallesCliente.means_of_contact}
              </li>
              <li className="list-group-item">
                <strong>Fecha de creación:</strong>{" "}
                {detallesCliente.created_at.slice(0, 10)}
              </li>
            </ul>
          </div>

          <div className="d-flex justify-content-end mt-2 ">
          {!pathname.includes("pedido") && (
            <Link
              to={`/inblock/logistica/cliente/${detallesCliente.id}/obras`}
              className="btn btn-secondary mb-2"
              style={{ /* backgroundColor: "#00C08B", */ color: "white" }}
            >
              Ver Obras
            </Link>
            )}
          </div>

          <div className="d-flex justify-content-end mt-2 ">
            {pathname.includes("logistica") && !pathname.includes("pedido") && (
            <Link
              to={`/inblock/logistica/cliente/${detallesCliente.id}/condicion-venta`}
              className="btn btn-secondary mb-2"
              style={{ /* backgroundColor: "#00C08B", */ color: "white" }}
            >
              Ver Condiciones de Venta
            </Link>
            )}
          </div>

          <div className="d-flex justify-content-end mt-2 ">
            {pathname.includes("pedido") && (
              <Link
                to={`/inblock/logistica/cliente/${detallesCliente.id}/obras/realizar-pedido`}
                className="btn  mb-2"
                style={{ backgroundColor: "#00C08B", color: "white" }}
              >
                Continuar
              </Link>
            )}

            {pathname.includes("cotizacion") && (
              <Link
                to={`/concreco/comercializacion/cliente/${detallesCliente.id}/obras/realizar-cotizacion`}
                className="btn  mb-2"
                style={{ backgroundColor: "#00C08B", color: "white" }}
              >
                Continuar
              </Link>
            )}

            {pathname.includes("comercializacion") &&
              !pathname.includes("cotizacion") && (
                <Link
                  to={`/concreco/comercializacion/cliente/${detallesCliente.id}/obras`}
                  className="btn mb-2"
                  style={{ backgroundColor: "#00C08B", color: "white" }}
                >
                  Ver Obras
                </Link>
              )}
          </div>



          <div className="d-flex justify-content-end mt-2 ">
              {pathname.includes("logistica") && !pathname.includes("pedido") && (
              <Link
                to={`/concreco/logistica/cliente/${detallesCliente.id}/condicion-venta`}
                className="btn mb-2"
                style={{  color: "white" }}
              >
                Ver Condiciones de Venta
              </Link>
            )}
          </div>
        </>
      ) : (
        <p>No existe cliente #{urlId} </p>
      )}
    </div>
  );
};
