import React from "react";
import { Redirect, Route, Switch, useRouteMatch } from "react-router-dom";
import { InblockLayout } from "../../../components/inblock/inblock-layout/InblockLayout";
import { MainSectionCover } from "../../../components/main-section-cover/MainSectionCover";

import { MobileMenuButtons } from "../../../components/mobile-menu-buttons/MobileMenuButtons";
import { useIsDesktop } from "../../../hooks/useIsDesktop";
import { inblockLogisticaLinks } from "../../../utils/inblockNavigationLinks";

import { InblockListaClientes } from "../inblock-lista-clientes/InblockListaClientes";
import styles from "../inblock-home/InblockHomePage.module.scss";
import { InblockClientePage } from "../inblock-cliente/InblockClientePage";
import { InblockObraPage } from "../inblock-obra/InblockObraPage";
import { InblockObrasDeCliente } from "../inblock-obras-de-cliente/InblockObrasDeCliente";
import { InblockAgregarClientePage } from "../inblock-agregar-cliente/InblockAgregarClientePage";
import { InblockCondicionesDeCliente } from "../inblock-condiciones-de-cliente/InblockCondicionesDeCliente";
import { InblockCondicionVentaPage } from "../inblock-condicion-venta/InblockCondicionVentaPage";
import { InblockListaPedidosPage } from "../inblock-lista-pedidos/InblockListaPedidosPage";
import { InblockAgregarObraPage } from "../inblock-agregar-obra/InblockAgregarObraPage";
import { InblockAgregarCondicionPage } from "../inblock-agregar-condicion/InblockAgregarCondicionPage";
import { InblockEditarClientePage } from "../inblock-editar-cliente/InblockEditarClientePage";
import { InblockPedidoPage } from "../inblock-pedido/InblockPedidoPage";
import { InblockAgregarPedidoPage } from "../inblock-agregar-pedido/InblockAgregarPedidoPage";
import { InblockEditarPedidoPage } from "../inblock-editar-pedido/InblockEditarPedidoPage";
import { InblockEditarObraPage } from "../inblock-editar-obra/InblockEditarObraPage";
import { InblockListaConductores } from "../inblock-lista-conductores/InblockListaConductores";
import { InblockAgregarConductorPage } from "../inblock-agregar-conductor/InblockAgregarConductorPage";
import { InblockListaViajesPage } from "../inblock-lista-viajes/InblockListaViajesPage";
import { InblockAgregarViajePage } from "../inblock-agregar-viaje/InblockAgregarViajePage";



export const InblockLogisticaPage = () => {
  const { path } = useRouteMatch();
  const isDesktop = useIsDesktop();
  
  return (
    <InblockLayout sectionTitle="Logística" navLinksArr={inblockLogisticaLinks}>
      <Switch>
        <Route exact path={`${path}`}>
          {isDesktop ? (
            <MainSectionCover text="Logística" empresa="inblock" />
          ) : (
            <div className={styles.svgContainer}>
              <div className={styles.mobileMainContent}>
                <div>
                  {inblockLogisticaLinks &&
                    inblockLogisticaLinks.map(userLink => (
                      <MobileMenuButtons
                        key={userLink.id}
                        userLinkObj={userLink}
                        app="inblock"
                      />
                    ))}
                </div>
              </div>
            </div>
          )}
        </Route>
        <Route exact path={`${path}/clientes`}>
          <div className="p-3">
            <InblockListaClientes />
            
          </div>
        </Route>
        <Route exact path={`${path}/cliente/:id`}>
          <div className="p-3">
            <InblockClientePage />
          </div>
        </Route>
        <Route exact path={`${path}/cliente/:id/obras`}>
          <div className="p-3">
            <InblockObrasDeCliente />
          </div>
        </Route>
        <Route exact path={`${path}/cliente/:id/condicion-venta`}>
          <div className="p-3">
            <InblockCondicionesDeCliente />
          </div>
        </Route>
        <Route exact path={`${path}/cliente/:idCliente/condicion-venta/:id`}>
          <div className="p-3">
          <InblockCondicionVentaPage />
          {/* <p>Agregar obra a cliente page</p> */}
          </div>
        </Route>
        <Route exact path={`${path}/agregar-condicion-venta/:id`}>
          <div className="p-3">
          <InblockAgregarCondicionPage />
          {/* <p>Agregar obra a cliente page</p> */}
          </div>
        </Route>
        <Route exact path={`${path}/agregar-cliente`}>
          <div className="p-3">
            <InblockAgregarClientePage />
          </div>
        </Route>
        <Route exact path={`${path}/editar-cliente/:id`}>
          <div className="p-3">
            <InblockEditarClientePage />
          </div>
        </Route>
        <Route exact path={`${path}/cliente/:idCliente/obra/:id`}>
          <div className="p-3">
          <InblockObraPage />
          {/* <p>Agregar obra a cliente page</p> */}
          </div>
        </Route>
        <Route exact path={`${path}/agregar-obra/:id`}>
          <div className="p-3">
          <InblockAgregarObraPage />
            {/* <p>Agregar obra a cliente page</p> */}
          </div>
        </Route>
        <Route exact path={`${path}/editar-obra/:id`}>
          <div className="p-3">
          <InblockEditarObraPage />
          {/* <p>Editar obra page</p> */}
          </div>
        </Route>
        <Route exact path={`${path}/pedidos`}>
          <div className="p-3">
            <InblockListaPedidosPage />
            {/* <p>Pagina pedidos</p> */}
          </div>
        </Route>
        <Route exact path={`${path}/pedido/:id`}>
          <div className="p-3">
            <InblockPedidoPage />
            {/* <p>Pagina pedidos</p> */}
          </div>
        </Route>
        <Route exact path={`${path}/editar-pedido/:id`}>
          <div className="p-3">
            <InblockEditarPedidoPage />
          </div>
        </Route>
        <Route exact path={`${path}/clientes-pedido`}>
          <div className="p-3">
            <InblockListaClientes />
            {/* <p>Pagina pedidos</p> */}
          </div>
        </Route>
        <Route exact path={`${path}/cliente/:id/realizar-pedido`}>
          <div className="p-3">
            <InblockClientePage />
            {/* <p>Pagina pedidos</p> */}
          </div>
        </Route>
        <Route exact path={`${path}/cliente/:idCliente/obra/:id/realizar-pedido`}>
          <div className="p-3">
            <InblockObraPage />
            {/* <p>Pagina pedidos</p> */}
          </div>
        </Route>

        <Route exact path={`${path}/cliente/:id/obras/realizar-pedido`}>
          <div className="p-3">
            <InblockObrasDeCliente />
            {/* <p>Pagina pedidos</p> */}
          </div>
        </Route>
        <Route exact path={`${path}/obra/:idObra/condicion-venta/:id/realizar-pedido`}>
          <div className="p-3">
            <InblockCondicionesDeCliente />
            {/* <p>Pagina pedidos</p> */}
          </div>
        </Route>
        <Route exact path={`${path}/obra/:idObra/condicion-venta/:idCondicion/form-pedido`}>
          <div className="p-3">
            <InblockAgregarPedidoPage />
            {/* <p>Pagina pedidos</p> */}
          </div>
        </Route>

        {/* Conductores */}

        <Route exact path={`${path}/conductores`}>
          <div className="p-3">
            <InblockListaConductores />
          </div>
        </Route>
        <Route exact path={`${path}/agregar-conductor`}>
          <div className="p-3">
            <InblockAgregarConductorPage />
            {/* <p>Pagina agregar conductor</p> */}
          </div>
        </Route>

        {/* Viajes */}
        <Route exact path={`${path}/viajes`}>
          <div className="p-3">
            <InblockListaViajesPage />
           
          </div>
        </Route>

        <Route exact path={`${path}/crear-viajes`}>
          <div className="p-3">
            <InblockListaPedidosPage />
           
          </div>
        </Route>

        <Route exact path={`${path}/crear-viaje/:idPedido`}>
          <div className="p-3">
            <InblockAgregarViajePage />
           
          </div>
        </Route>
        
        <Route exact path={`${path}/asignar-pedido/:idViaje`}>
          <div className="p-3">
            <InblockListaPedidosPage />
           {/* <p>Pagina detalle de viaje </p> */}
          </div>
        </Route>

        <Route exact path={`${path}/asignar-pedido/:idPedido/viaje/idViaje`}>
          <div className="p-3">
            {/* <InblockListaPedidosPage /> */}
           <p>Pagina asiganr de viaje </p>
          </div>
        </Route>



        <Route path={`${path}/*`}>
          <Redirect to="/inblock/home" />
        </Route>
      </Switch>
    </InblockLayout>
  );
};
