import React, { useContext, useEffect, useState } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";

/* 
import { SearchInput } from "../../components/search-input/SearchInput";
 */

import { BotonesPaginacionTablas } from "../../../components/botones-paginacion-tablas/BotonesPaginacionTablas";
import { jwtFetchData } from "../../../utils/jwtFetchData";
import { LoadingSpinner } from "../../../components/loading-spinner/LoadingSpinner";
import { ReactReduxContext } from "../../../context/reactReduxContext";
import { InblockTablaConductores } from "../../../components/inblock/inblock-tabla-conductores/InblockTablaConductores";

export const InblockListaConductores = () => {
  const [conductores, setConductores] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [finalPage, setFinalPage] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [busquedaAplicada, setBusquedaAplicada] = useState("");

  const history = useHistory();

  const { pathname } = useLocation();

  const {
    dispatch,
    inblock: {
      setInblockCurrentUser,
      inblockCurrentUserAccessToken,
      inblockCurrentUserRefreshToken,
    },
  } = useContext(ReactReduxContext);

  useEffect(() => {
    fetchConductores(`${process.env.REACT_APP_INBLOCK_BASE_URL}pedidos/conductores/`);
  }, []);

  const fetchConductores = async url => {
    setIsLoading(true);
    const data = await jwtFetchData(
      url,
      `${process.env.REACT_APP_INBLOCK_BASE_URL}auth/token/refresh/`,
      inblockCurrentUserAccessToken,
      inblockCurrentUserRefreshToken,
      dispatch,
      setInblockCurrentUser
    );

    setConductores(data);
    // setFinalPage(Math.ceil(data.count / 10));
    setIsLoading(false);
  };

  // const changePage = num => {
  //   const newCurrentPage = currentPage + num;

  //   fetchConductores(
  //     `${process.env.REACT_APP_INBLOCK_BASE_URL}conductores/?search=${
  //       busquedaAplicada ? busquedaAplicada : ""
  //     }&page=${newCurrentPage}`
  //   );

  //   setCurrentPage(newCurrentPage);
  // };

  return (
    <Container style={{ maxWidth: "100%", maxHeight: "100%" }}>
      <Row className="text-center mb-3">
        {pathname.includes("pedido") || pathname.includes("cotizacion") ? (
          <h3 className="m-0">Seleccionar Chofer</h3>
        ) : (
          <h3 className="m-0">Lista de conductores</h3>
        )}
      </Row>
      <div className="d-flex justify-content-end mb-3">
      <Button
        variant="primary"
        onClick={() => {
          history.push("/inblock/logistica/agregar-conductor");
        }}
      >
        Agregar Conductor
      </Button>
      </div>
      <Row className="mb-3 text-center">
        {/* <div className="col-12 col-sm-7 mb-3">
          <SearchInput
            setConductores={setConductores}
            setCurrentPage={setCurrentPage}
            setFinalPage={setFinalPage}
            url=process.env.REACT_APP_API_CONCRECO_BACKEND_URL + "/api/conductores/?search="
            setIsLoading={setIsLoading}
            setBusquedaAplicada={setBusquedaAplicada}
          />
        </div> */}
        {/* <div className="col">
          {pathname.includes("logistica") &&
            !pathname.includes("conductores-pedido") && (
              <Link to="/concreco/logistica/agregar-cliente">
                <Button variant="primary">Agregar Cliente</Button>
              </Link>
            )}
          {pathname.includes("logistica") &&
            pathname.includes("conductores-pedido") && (
              <Link to="/concreco/logistica/agregar-cliente/realizar-pedido">
                <Button variant="primary">Agregar Cliente</Button>
              </Link>
            )}
          {pathname.includes("comercializacion") && (
            <Link to="/concreco/comercializacion/agregar-cliente">
              <Button variant="primary">Agregar Cliente</Button>
            </Link>
          )}
        </div> */}
      </Row>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <Row>
          <InblockTablaConductores conductores={conductores} />
          {/* <BotonesPaginacionTablas
            currentPage={currentPage}
            finalPage={finalPage}
            changePage={changePage}
          /> */}
        </Row>
      )}
    </Container>
  );
};
