import { useRef } from "react";

import { ReactComponent as IconoMenuBlack } from "../../../assets/svg/iconoMenuBlack.svg";
import { Link } from "react-router-dom";
import { InblockLogOutButton } from "../inblock-logout-btn/InblockLogOutBtn";

import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Container from "react-bootstrap/Container";

export const InblockHeaderMobile = ({ title, links }) => {
  const navRef = useRef();

  return (
    <Navbar
      collapseOnSelect
      expand="lg"
      style={{ backgroundColor: "#FFDD00" }}
      ref={navRef}
    >
      <Container fluid>
        <Navbar.Toggle
          style={{ padding: "0" }}
          aria-controls="navbarScroll"
          children={
            <div style={{ width: "40px" }}>
              <IconoMenuBlack />
            </div>
          }
        />
        <h2 style={{ margin: "0", color: "black" }}>{title}</h2>

        <InblockLogOutButton />

        <Navbar.Collapse id="navbarScroll" style={{ marginLeft: "50px" }}>
          <Nav className="me-auto my-2 my-lg-0">
            <Nav.Link as={"span"} href="#" className="p-0 d-flex">
              <Link
                className="nav-link p-0"
                style={{ color: "black", fontSize: "18px" }}
                aria-current="page"
                to="/inblock/home"
              >
                Inicio
              </Link>
            </Nav.Link>

            {links.map((link) => (
              <Nav.Link
                as={"span"}
                href="#"
                className="p-0 d-flex"
                key={link.id}
              >
                <Link
                  className="nav-link p-0"
                  style={{ color: "black", fontSize: "18px" }}
                  aria-current="page"
                  to={link.link}
                >
                  {link.text}
                </Link>
              </Nav.Link>
            ))}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};
