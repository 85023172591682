import React from "react";
import { useParams } from "react-router-dom";
import { InblockListaCondicionesDeCliente } from "../../../components/inblock/inblock-lista-condiciones-de-cliente/InblockListaCondicionesDeCliente";
import { LoadingSpinner } from "../../../components/loading-spinner/LoadingSpinner";
import { useJwtFetchAndLoading } from "../../../hooks/useJwtFetchAndLoading";

export const InblockCondicionesDeCliente = () => {
  const { id, idObra } = useParams();

  const { data, isLoading } = useJwtFetchAndLoading(
    `${process.env.REACT_APP_INBLOCK_BASE_URL}clientes/${id}/condicion-venta`,
    id
  );

  return isLoading ? (
    <LoadingSpinner />
  ) : (
    <>
      <InblockListaCondicionesDeCliente data={data} clienteId={id} idObra={idObra} />
    </>
  );
};
