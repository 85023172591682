import React, { useContext, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import Table from "react-bootstrap/Table";

import { ReactReduxContext } from "../../../context/reactReduxContext";
import { setCurrentUser } from "../../../redux/user/userSlice";

import { SuccessModal } from "../../success-modal/SuccessModal";

export const InblockTablaVentas = ({ pedidos, setPedidos }) => {
  const [show, setShow] = useState(false);

  const { pathname } = useLocation();

  const { authtoken, dispatch, userRol } = useContext(ReactReduxContext);

  const handleClose = () => setShow(false);

  const handleClick = async (e, pedido) => {
    try {
      e.target.classList.add("d-none");

      e.target.nextSibling.classList.remove("d-none");

      let formData = new FormData();

      let status_pedido;

      if (pedido.status_pedido === "Desactivado") {
        status_pedido = "Activado";
      }

      if (pedido.status_pedido === "Activado") {
        status_pedido = "Desactivado";
      }

      formData.append("status_pedido", status_pedido);

      let data = await fetch(
        `${process.env.REACT_APP_API_CONCRECO_BACKEND_URL}/api/pedidos/${pedido.id}/update/`,
        {
          method: "PATCH",
          headers: {
            Authorization: `Token ${authtoken}`,
          },
          body: formData,
        }
      );

      let json = await data.json();

      if (json.expired) {
        dispatch(setCurrentUser({ token: json.token }));

        data = await fetch(
          `${process.env.REACT_APP_API_CONCRECO_BACKEND_URL}/api/pedidos/${pedido.id}/update/`,
          {
            method: "PATCH",
            headers: {
              Authorization: `Token ${json.token}`,
            },
            body: formData,
          }
        );

        json = await data.json();
      }

      if (data.status === 201 || data.status === 200) {
        setShow(true);
        setPedidos((prevState) => {
          const arr = [...prevState];

          const arrElementsStringifed = arr.map((el) => JSON.stringify(el));

          const idx = arrElementsStringifed.indexOf(JSON.stringify(pedido));

          arr.splice(idx, 1, json);

          return arr;
        });
      }
    } catch (error) {
      console.log(error);
      alert(error);
    } finally {
      e.target.nextSibling.classList.add("d-none");
      e.target.classList.remove("d-none");
    }
  };

  return (
    <>
      <SuccessModal
        show={show}
        handleClose={handleClose}
        title="Pedido actualizado"
      />
      <div className="table-responsive p-0">
        <Table striped bordered hover className="text-center">
          <thead>
            <tr>
              
              <th>Cliente</th>
              <th>Alias</th>
              <th>Obra</th>
              <th>Material</th>
              <th>Color</th>
              <th>Medida</th>
              <th>Precio</th>
              <th>Asesor</th>
              <th>F/G</th>
              <th>Método de Pago</th>
              <th>Uso de CFDI</th>
              <th>Forma de factruración</th>
              <th>Fecha de entrega</th>
              <th>Horario</th>
              <th>Fecha de solicitud</th>
              
              

              {/* <th>Obra</th>
              {pathname.includes("logistica") && <th>Estatus de pago</th>}
              {pathname.includes("logistica") &&
                userRol === "Administracion" && <th>Comprobante de pago</th>} */}
            </tr>
          </thead>
          <tbody>
            {pedidos.map((pedido) => (
              <tr
                key={pedido.id}
                // className={`${
                //   pathname.includes("logistica")
                //     ? userRol === "Administracion"
                //       ? pedido.status_pedido === "Activado"
                //         ? "table-success"
                //         : "table-danger"
                //       : ""
                //     : pathname.includes("produccion")
                //     ? userRol === "Operador"
                //       ? pedido.reporte_operador.salida_planta &&
                //         (!pedido.reporte_operador.llegada_obra ||
                //           !pedido.reporte_operador.inicio_bombeo ||
                //           !pedido.reporte_operador.fin_bombeo ||
                //           !pedido.reporte_operador.incidencia)
                //         ? "table-warning"
                //         : pedido.reporte_operador.causa_retraso
                //         ? "table-success"
                //         : "table-danger"
                //       : pedido.status_pedido === "Activado"
                //       ? "table-success"
                //       : "table-danger"
                //     : ""
                // }`}
              >
                <td>
                  
                    {pedido.client.name}
                  
                </td>
                <td>
                    {pedido.client.alias}
                  
                </td>
                {/* <td>
                  <Link
                    to={
                      pathname.includes("logistica")
                        ? `/inblock/logistica/pedido/${pedido.id}`
                        : pathname.includes("produccion")
                        ? `/inblock/produccion/pedido/${pedido.id}`
                        : pathname.includes("dashboard") &&
                          `/inblock/dashboard/pedido/${pedido.id}`
                    }
                  >
                    {pedido.delibery_date.slice(0, 10)}
                  </Link>
                </td> */}
                <td>
                    {pedido.construction_site.name}
                </td>
                <td>
                    {pedido.material}
                </td>
                <td>
                    {pedido.material_color}
                </td>
                <td>
                    {pedido.material_measures}
                </td>
                <td>
                    {"$"+pedido.net_price}
                </td>
                <td>
                    {pedido.assessor.name+" "+pedido.assessor.last_name}
                </td>
                <td>
                    {pedido.sell_condition.payment_fiscal ? "F" : "G"}
                </td>
                <td>
                    {pedido.sell_condition.payment_method}
                </td>
                <td>
                    {pedido.sell_condition.cfdi}
                </td>
                <td>
                    {pedido.sell_condition.billing_type}
                </td>
                <td>
                    {pedido.delibery_date}
                </td>
                <td>
                    {pedido.delibery_hr}
                </td>
                <td>
                    {pedido.created_at.slice(0, 10)}
                </td>

                
                
                
                
                
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    </>
  );
};