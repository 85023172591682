import React from "react";

import Table from "react-bootstrap/Table";
import { Link, useLocation } from "react-router-dom";

export const InblockTablaClientes = ({ clientes }) => {
  const { pathname } = useLocation();
  return (
    <div className="table-responsive p-0">
      <Table striped bordered hover className="text-center ">
        <thead>
          <tr>
            <th>ID</th>
            <th>Nombre</th>
            <th>Alias</th>
            <th>Fecha</th>
          </tr>
        </thead>
        <tbody className="text-reset">
          {clientes.map((cliente) => (
            <tr key={cliente.id} className="table-row">
              <td>
              {pathname.includes("pedido") && (
                <Link to={`/inblock/logistica/cliente/${cliente.id}/realizar-pedido`}>
                  {cliente.id}
                </Link>
              )}
              {pathname.includes("logistica") && !pathname.includes("pedido") && (
                <Link to={`/inblock/logistica/cliente/${cliente.id}`}>
                  {cliente.id}
                </Link>
              )}
              

              </td>
              <td>
              {pathname.includes("pedido") && (
                <Link to={`/inblock/logistica/cliente/${cliente.id}/realizar-pedido`}>
                  {cliente.name}
                </Link>
              )}
              {pathname.includes("logistica") && !pathname.includes("pedido") && (
                <Link to={`/inblock/logistica/cliente/${cliente.id}`}>
                  {cliente.name}
                </Link>
              )}
              </td>
              <td>
              {pathname.includes("pedido") && (
                <Link to={`/inblock/logistica/cliente/${cliente.id}/realizar-pedido`}>
                  {cliente.alias}
                </Link>
              )}
              {pathname.includes("logistica") && !pathname.includes("pedido") && (
                <Link to={`/inblock/logistica/cliente/${cliente.id}`}>
                  {cliente.alias}
                </Link>
              )}
              </td>
              <td>
              {pathname.includes("pedido") && (
                <Link to={`/inblock/logistica/cliente/${cliente.id}/realizar-pedido`}>
                  {cliente.created_at.slice(0, 10)}
                </Link>
              )}
              {pathname.includes("logistica") && !pathname.includes("pedido") && (
                <Link to={`/inblock/logistica/cliente/${cliente.id}`}>
                  {cliente.created_at.slice(0, 10)}
                </Link>
              )}                
 
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
};
