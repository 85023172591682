import React from "react";
import { useParams } from "react-router-dom";
import { InblockDetalleObra } from "../../../components/inblock/inblock-detalles-obra/InblockDetalleObra";
import { LoadingSpinner } from "../../../components/loading-spinner/LoadingSpinner";
import { useJwtFetchAndLoading } from "../../../hooks/useJwtFetchAndLoading";

export const InblockObraPage = () => {
  const { id, idCliente } = useParams();

  const { data, isLoading } = useJwtFetchAndLoading(
    `${process.env.REACT_APP_INBLOCK_BASE_URL}clientes/${idCliente}/obras/${id}`,
    id
  );

  return isLoading ? (
    <LoadingSpinner />
  ) : (
    <InblockDetalleObra detalleObra={data} urlId={id} />
  );
};
