import React, { useContext, useEffect, useRef, useState } from "react";
import { useHistory, useParams, useLocation } from "react-router-dom";
import { ReactReduxContext } from "../../../context/reactReduxContext";
import { setInblockCurrentUser } from "../../../redux/inblock/user/userSlice";
import { jwtFetchData } from "../../../utils/jwtFetchData";

import {
  OpcionesSelectPago,
  OpcionesSelectCfdi,
  OpcionesSelectMetodoPago,
  OpcionesSelectFormaPago,
  OpcionesSelectContacto,
  OpcionesSelectGiro,
  OpcionesSelectTipoVenta,
} from "../../../utils/selects-opciones";

import { CustomSelect } from "../../custom-select/CustomSelect";
import { ModalRedirect } from "../../modal-redirect/ModalRedirect";

const formInitialState = {
  address: "",
  destination_city: "",
  email:"",
  image: "",
  link: "",
  telefono: "",
  email: "",
  phone: "",
  references: "",
  resident: "",
  name:"",
};

export const InblockFormAgregarObra = ({ choicesData, formToEdit, clienteId }) => {
  const [form, setForm] = useState(formInitialState);
  const [showConfirmModal, setShowConfirmModal] = useState(false);

  const {
    dispatch,
    inblock: {
      inblockCurrentUserAccessToken,
      inblockCurrentUserRefreshToken,
      setInblockCurrentUser,
    },
  } = useContext(ReactReduxContext);

  const history = useHistory();
  const { id } = useParams();
  const { pathname } = useLocation();

  const pagoRef = useRef();
  const municipioRef = useRef();
  const rfcInputRef = useRef();
  const cfdiInputRef = useRef();
  const metodoPagoInputRef = useRef();
  const formaPagoInputRef = useRef();
  const correoFacturasInputRef = useRef();
  const estadoInputRef = useRef();
  const municipioInputRef = useRef();

useEffect(() => {
//     for (let estado in choicesData.Estados) {
//       let option = document.createElement("option");
//       option.value = estado;
//       option.textContent = estado;
//       document.querySelector("#estados-select").appendChild(option);
//     }

//     document.querySelector("#estados-select").addEventListener("change", e => {
//       document.querySelector("#municipios-select").innerHTML =
//         "<option></option>";
//       for (let municipio of choicesData.Estados[e.target.value]) {
//         const option = document.createElement("option");
//         option.value = municipio;
//         option.textContent = municipio;
//         document.querySelector("#municipios-select").appendChild(option);
//       }
//     });

    const verificarSiClienteExiste = async () => {
      const json = await jwtFetchData(
        `${process.env.REACT_APP_INBLOCK_BASE_URL}obras/${id}/`,
        `${process.env.REACT_APP_INBLOCK_BASE_URL}auth/token/refresh/`,
        inblockCurrentUserAccessToken,
        inblockCurrentUserRefreshToken,
        dispatch,
        setInblockCurrentUser
      );

      setForm({
        nombre: json.name,
        alias: json.alias,
        rfc: json.rfc,
        correo_facturas: json.email_invoice,
        direccion: json.address,
        telefono: json.phone,
        email: json.email,
        sitio_web: json.website,
        codigo_postal: json.postal_code,
        estado_republica: json.republic_state,
        municipio: json.township,
        giro_de_empresa: json.company_type,
        medio_contacto: json.means_of_contact,
        nombre_cpp: json.name_payments_pendings,
        telefono_cpp: json.phone_payments_pendings,
        correo_cpp: json.email_payments_pendings,
        /* tipo_venta: json.tipo_venta, */
      });

//       // if (pagoRef.current.value === "G") {
//       //   rfcInputRef.current.disabled = true;
//       //   cfdiInputRef.current.disabled = true;
//       //   metodoPagoInputRef.current.disabled = true;
//       //   formaPagoInputRef.current.disabled = true;
//       //   correoFacturasInputRef.current.disabled = true;
//       // }

//       document.querySelector("#municipios-select").addEventListener(
//         "focus",
//         e => {
//           if (
//             choicesData.Estados[estadoInputRef.current.value].includes(
//               json.municipio
//             )
//           ) {
//             document.querySelector(
//               "#municipios-select"
//             ).innerHTML = `<option value="${json.municipio}">${json.municipio}</option>`;
//           } else {
//             console.log("no lo incluye");
//             document.querySelector(
//               "#municipios-select"
//             ).innerHTML = `<option value=""></option>`;
//           }

//           for (let municipio of choicesData.Estados[
//             estadoInputRef.current.value
//           ]) {
//             const option = document.createElement("option");
//             option.value = municipio;
//             option.textContent = municipio;
//             document.querySelector("#municipios-select").appendChild(option);
//           }
//         },
//         { once: true }
//       );
    };

    if (formToEdit) {
      verificarSiClienteExiste();
    }
 }, []);

  const handleChange = e => {
    const value = e.target.value;
    const name = e.target.name;

    // if (e.target.name === "pago") {
    //   if (e.target.value === "G") {
    //     setForm({
    //       ...form,
    //       pago: "G",
    //       rfc: "",
    //       CFDI: "",
    //       metodo_pago: "",
    //       forma_pago: "",
    //       correo_facturas: "",
    //     });

    //     rfcInputRef.current.disabled = true;
    //     cfdiInputRef.current.disabled = true;
    //     metodoPagoInputRef.current.disabled = true;
    //     formaPagoInputRef.current.disabled = true;
    //     correoFacturasInputRef.current.disabled = true;
    //   } else {
    //     setForm({
    //       ...form,
    //       pago: "F",
    //       CFDI: cfdiInputRef.current.value,
    //       metodo_pago: metodoPagoInputRef.current.value,
    //       forma_pago: formaPagoInputRef.current.value,
    //     });

    //     rfcInputRef.current.disabled = false;
    //     cfdiInputRef.current.disabled = false;
    //     metodoPagoInputRef.current.disabled = false;
    //     formaPagoInputRef.current.disabled = false;
    //     correoFacturasInputRef.current.disabled = false;
    //   }

    //   return;
    // }

    // if (e.target.name === "estado_republica") {
    //   setForm({
    //     ...form,
    //     estado_republica: estadoInputRef.current.value,
    //     municipio: "",
    //   });

    //   return;
    // }

    setForm({
      ...form,
      [name]: value,
    });
  };

  const handleSubmit = async (e, historyRef) => {
    e.preventDefault();

    const formulario = {
      address: form.address,
      destination_city: form.destination_city,
      // payment_fiscal: form.pago === "F" ? true : false,
      email: form.email,
      image: form.image,
      email: form.email,
      link: form.link,
      phone: form.phone,
      references: form.references,
      resident: form.resident,
      cliente : clienteId,
      name: form.name,
    };

    if (formToEdit) {
      let data = await fetch(
        `${process.env.REACT_APP_INBLOCK_BASE_URL}clientes/${id}`,
        {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${inblockCurrentUserAccessToken}`,
          },
          body: JSON.stringify(formulario),
        }
      );

      let json = await data.json();

      if (data.status === 401) {
        data = await fetch(
          `${process.env.REACT_APP_INBLOCK_BASE_URL}auth/token/refresh/`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ refresh: inblockCurrentUserRefreshToken }),
          }
        );

        json = await data.json();

        if (data.status === 200) {
          data = await fetch(
            `${process.env.REACT_APP_INBLOCK_BASE_URL}clientes/${id}`,
            {
              method: "PATCH",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${inblockCurrentUserAccessToken}`,
              },
              body: JSON.stringify(formulario),
            }
          );

          json = await data.json();

          dispatch(
            setInblockCurrentUser({
              access: json.access,
              refresh: inblockCurrentUserRefreshToken,
            })
          );
        }
      }

      if (data.status === 400) {
        if (json.email) {
          alert("Este correo ya ha sido registrado");
        }
        if (json.telefono) {
          alert("Este teléfono ya ha sido registrado");
          return;
        } else {
          alert(JSON.stringify(json));
        }
      }

      if (data.status === 201) {
        setShowConfirmModal(true);
      }

      if (data.status === 406) {
        alert(json.error);
      }
    } else {
      let data = await fetch(
        `${process.env.REACT_APP_INBLOCK_BASE_URL}clientes/obras`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${inblockCurrentUserAccessToken}`,
          },
          body: JSON.stringify(formulario),
        }
      );

      let json = await data.json();

      if (data.status === 401) {
        data = await fetch(
          `${process.env.REACT_APP_INBLOCK_BASE_URL}auth/token/refresh/`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ refresh: inblockCurrentUserRefreshToken }),
          }
        );

        json = await data.json();

        if (data.status === 200) {
          data = await fetch(
            `${process.env.REACT_APP_INBLOCK_BASE_URL}clientes/obras`,
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${inblockCurrentUserAccessToken}`,
              },
              body: JSON.stringify(formulario),
            }
          );

          json = await data.json();

          dispatch(
            setInblockCurrentUser({
              access: json.access,
              refresh: inblockCurrentUserRefreshToken,
            })
          );
        }
      }

      if (data.status === 400) {
        if (json.email) {
          alert("Este correo ya ha sido registrado");
        }
        if (json.telefono) {
          alert("Este teléfono ya ha sido registrado");
          return;
        } else {
          alert(JSON.stringify(json));
        }
      }

      if (data.status === 201) {
        setShowConfirmModal(true);
      }

      if (data.status === 406) {
        alert(json.error);
      }
    }
  };

  return (
    <div className="container">
      <ModalRedirect
        showConfirmModal={showConfirmModal}
        text={
          formToEdit
            ? "Se han actualizado correctamente los datos del cliente."
            : "Se ha creado correctamente la obra."
        }
        link={
          formToEdit
            ? pathname.includes("logistica")
              ? pathname.includes("realizar-pedido")
                ? `/inblock/logistica/cliente/${id}/realizar-pedido`
                : `/inblock/logistica/cliente/${id}`
              : pathname.includes("comercializacion") &&
                `/inblock/comercializacion/cliente/${id}`
            : pathname.includes("logistica")
            ? pathname.includes("realizar-pedido")
              ? "/inblock/logistica/clientes-pedido"
              : "/inblock/logistica/clientes"
            : pathname.includes("comercializacion") &&
              "/inblock/comercializacion/clientes"
        }
      />
      <div className="row justify-content-center">
        <div className="col-12 col-sm-8">
          {formToEdit ? (
            <h3 className="text-center">Editar Obra</h3>
          ) : (
            <h3 className="text-center">Agregar Obra</h3>
          )}

          <form
            className="agregar-cliente-form"
            onSubmit={e => {
              handleSubmit(e, history);
            }}
          >
            
            <div className="mb-2">
              <label htmlFor="name" className="form-label">
                Nombre
              </label>
              <input
                type="text"
                name="name"
                id="name"
                value={form.name}
                onChange={handleChange}
                className="form-control"
                autoComplete="off"
                required
              />
            </div> 
            <div className="mb-2">
              <label htmlFor="resident" className="form-label">
                Residente
              </label>
              <input
                type="text"
                name="resident"
                id="resident"
                value={form.resident}
                onChange={handleChange}
                className="form-control"
                autoComplete="off"
                required
              />
            </div>

            <div className="mb-2">
              <label htmlFor="address" className="form-label">
                Dirección
              </label>
              <input
                type="text"
                name="address"
                id="address"
                value={form.address}
                onChange={handleChange}
                className="form-control"
                autoComplete="off"
                required
              />
            </div>

            <div className="mb-2">
              <label htmlFor="destination_city" className="form-label">
                Ciudad
              </label>
              <input
                type="text"
                name="destination_city"
                id="destination_city"
                value={form.destination_city}
                onChange={handleChange}
                className="form-control"
                autoComplete="off"
                required
              />
            </div>

            <div className="mb-2">
              <label htmlFor="phone" className="form-label">
                Teléfono
              </label>
              <input
                type="text"
                name="phone"
                id="phone"
                value={form.phone}
                onChange={handleChange}
                className="form-control"
                autoComplete="off"
                required
              />
            </div>

            <div className="mb-2">
              <label htmlFor="email" className="form-label">
                Email
              </label>
              <input
                type="email"
                name="email"
                id="email"
                value={form.email}
                onChange={handleChange}
                className="form-control"
                autoComplete="off"
              />
            </div>


            <div className="mb-2">
              <label htmlFor="references" className="form-label">
                Referencias
              </label>
              <input
                type="text"
                name="references"
                id="references"
                value={form.refrences}
                onChange={handleChange}
                className="form-control"
                autoComplete="off"
              />
            </div>

            <div className="mb-2">
              <label htmlFor="linkObra" className="form-label">
                Link Obra
              </label>
              <input
                type="linkObra"
                name="linkObra"
                id="linkObra"
                value={form.linkObra}
                onChange={handleChange}
                className="form-control"
                autoComplete="off"
              />
            </div>


              {/* <div className="col-12 col-md-6">
                <label htmlFor="alias" className="form-label">
                  Alias
                </label>
                <input
                  type="text"
                  name="alias"
                  id="alias"
                  value={form.alias}
                  onChange={handleChange}
                  className="form-control"
                  autoComplete="off"
                  required
                />
              </div>
            </div>

            <div className="row mb-2">
           

              

              <div className="col-12 col-md-6">
                <label htmlFor="rfc" className="form-label">
                  RFC
                </label>
                <input
                  type="text"
                  name="rfc"
                  id="rfc"
                  value={form.rfc}
                  onChange={handleChange}
                  className="form-control"
                  autoComplete="off"
                  ref={rfcInputRef}
                />
              </div>
              
              <div className="col-12 col-md-6">
                <label htmlFor="direccion" className="form-label">
                  Dirección
                </label>
                <input
                  type="text"
                  name="direccion"
                  id="direccion"
                  value={form.direccion}
                  onChange={handleChange}
                  className="form-control"
                  autoComplete="off"
                  required
                />
              </div>
            </div>

            <div className="row mb-2">
             

              <div className="col-12 col-md-6">
                <label htmlFor="telefono" className="form-label">
                  Teléfono
                </label>
                <input
                  type="number"
                  name="telefono"
                  id="telefono"
                  value={form.telefono}
                  onChange={handleChange}
                  className="form-control"
                  autoComplete="off"
                  required
                  onWheel={e => {
                    e.target.blur();
                  }}
                />
              </div>

              <div className="col-12 col-md-6">
                <label htmlFor="email" className="form-label">
                  Email
                </label>
                <input
                  type="email"
                  name="email"
                  id="email"
                  value={form.email}
                  onChange={handleChange}
                  className="form-control"
                  autoComplete="off"
                />
              </div>

            </div>

            <div className="row mb-2">
              <div className="col-12 col-md-6">
                <label htmlFor="estados-select" className="form-label">
                  Estado de la República
                </label>
                <select
                  id="estados-select"
                  name="estado_republica"
                  onChange={handleChange}
                  ref={estadoInputRef}
                  value={form && form.estado_republica}
                  className="form-select"
                  required
                >
                  <option></option>
                </select>
              </div>

            <div className="col-12 col-md-6">
                <label
                  htmlFor="municipios-select"
                  ref={municipioRef}
                  className="form-label"
                >
                  Municipio
                </label>
                <select
                  id="municipios-select"
                  name="municipio"
                  onChange={handleChange}
                  ref={municipioInputRef}
                  value={form.municipio && form.municipio}
                  className="form-select"
                  required
                >
                  <option>{form.municipio && form.municipio}</option>
                </select>
              </div>
            </div>

            <div className="row mb-2">
              <div className="col-12 col-md-6">
                <label htmlFor="codigo_postal" className="form-label">
                  Código Postal
                </label>
                <input
                  type="number"
                  name="codigo_postal"
                  id="codigo_postal"
                  value={form.codigo_postal}
                  onChange={handleChange}
                  className="form-control"
                  autoComplete="off"
                  required
                  onWheel={e => {
                    e.target.blur();
                  }}
                />
              </div>

              <div className="col-12 col-md-6">
                <CustomSelect
                  datos={OpcionesSelectGiro}
                  handleChange={handleChange}
                  isRequired={true}
                  form={form}
                />
              </div>
            </div>

            <div className="row mb-2">
              <div className="col-12 col-md-6">
                <label htmlFor="sitio_web" className="form-label">
                  Sitio Web
                </label>
                <input
                  type="text"
                  name="sitio_web"
                  id="sitio_web"
                  value={form.sitio_web}
                  onChange={handleChange}
                  className="form-control"
                  autoComplete="off"
                />
              </div>

              <div className="col-12 col-md-6">
                <CustomSelect
                  datos={OpcionesSelectContacto}
                  handleChange={handleChange}
                  isRequired={true}
                  form={form}
                />
              </div>

              
            </div>

        




            

            

            

            <div className="row mb-2">
              <div className="col-12 col-md-6">
                <label htmlFor="nombre_cpp" className="form-label">
                  Nombre de cuentas por pagar
                </label>
                <input
                  type="text"
                  name="nombre_cpp"
                  id="nombre_cpp"
                  value={form.nombre_cpp}
                  onChange={handleChange}
                  className="form-control"
                  autoComplete="off"
                />
              </div>

              <div className="col-12 col-md-6">
                <label htmlFor="telefono_cpp" className="form-label">
                  Télefono de cuentas por pagar
                </label>
                <input
                  type="number"
                  name="telefono_cpp"
                  id="telefono_cpp"
                  value={form.telefono_cpp}
                  onChange={handleChange}
                  className="form-control"
                  autoComplete="off"
                  onWheel={e => {
                    e.target.blur();
                  }}
                />
              </div>
            </div>
            
            <div className="row mb-2">
            <div className="col-12 col-md-6">
              <label htmlFor="correo_cpp" className="form-label">
                Correo de cuentas por pagar
              </label>
              <input
                type="email"
                name="correo_cpp"
                id="correo_cpp"
                value={form.correo_cpp}
                onChange={handleChange}
                className="form-control"
                autoComplete="off"
              />
            </div>

            

              <div className="col-12 col-md-6">
                <label htmlFor="correo_facturas" className="form-label">
                  Correo para envio de facturas
                </label>
                <input
                  type="email"
                  name="correo_facturas"
                  id="correo_facturas"
                  value={form.correo_facturas}
                  onChange={handleChange}
                  ref={correoFacturasInputRef}
                  autoComplete="off"
                  className="form-control"
                />
              </div> */}
            





            <div className="d-flex justify-content-end ">
              {formToEdit ? (
                <input
                  type="submit"
                  value="Guardar Cambios"
                  className="btn  mb-3 mt-2"
                  style={{ backgroundColor: "#00C08B", color: "white" }}
                />
              ) : (
                <input
                  type="submit"
                  value="Agregar"
                  className="btn mb-3 mt-2"
                  style={{ backgroundColor: "#00C08B", color: "white" }}
                />
              )}
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
