import { useContext } from "react";
import { Helmet } from "react-helmet";
import { Redirect, Route, Switch, useRouteMatch } from "react-router-dom";
import { InblockHomePage } from "./inblock-home/InblockHomePage";
import { InblockLoginPage } from "./inblock-login/InblockLoginPage";
import { InblockLogisticaPage } from "./inblock-logistica/InblockLogisticaPage";
import { ReactReduxContext } from "../../context/reactReduxContext";
import { InblockFacturacionPage } from "./inblock-facturacion/InblockFacturacionPage";
import { InblockUsuariosPage } from "./inblock-usuarios/InblockUsuariosPage";

import styles from "../../styles/inblock/Inblock.module.scss";

export const Inblock = () => {
  const { path } = useRouteMatch();

  const {
    inblock: { inblockCurrentUser },
  } = useContext(ReactReduxContext);

  return (
    <div className={styles.inblockContainer}>
      <Helmet>
        <title>Inblock</title>
        <link rel="icon" type="image/png" href="favicon-32x32.png" />
        <meta name="description" /* content="Concreco" */ />
      </Helmet>
      <Switch>
        <Route
          exact
          path={`${path}`}
          render={() =>
            inblockCurrentUser ? (
              <Redirect to={`${path}/home`} />
            ) : (
              <InblockLoginPage />
            )
          }
        />

        <Route
          exact
          path={`${path}/home`}
          render={() =>
            inblockCurrentUser ? (
              <InblockHomePage />
            ) : (
              <Redirect to={`${path}`} />
            )
          }
        />
        <Route
          path={`${path}/logistica`}
          render={() =>
            inblockCurrentUser ? (
              <InblockLogisticaPage />
            ) : (
              <Redirect to={`${path}`} />
            )
          }
        />
        <Route
          path={`${path}/facturacion`}
          render={() =>
            inblockCurrentUser ? (
              <InblockFacturacionPage />
            ) : (
              <Redirect to={`${path}`} />
            )
          }
        />

          <Route
          path={`${path}/usuarios`}
          render={() =>
            inblockCurrentUser ? (
              <InblockUsuariosPage />
              
            ) : (
              <Redirect to={`${path}`} />
            )
          }
        />

        <Route
          path={`${path}/*`}
          render={() =>
            inblockCurrentUser ? (
              <Redirect to="/inblock/home" />
            ) : (
              <Redirect to="/inblock" />
            )
          }
        />
      </Switch>
    </div>
  );
};
