import React from "react";
import { Redirect, Route, Switch, useRouteMatch } from "react-router-dom";
import { InblockLayout } from "../../../components/inblock/inblock-layout/InblockLayout";
import { MainSectionCover } from "../../../components/main-section-cover/MainSectionCover";

import { MobileMenuButtons } from "../../../components/mobile-menu-buttons/MobileMenuButtons";
import { useIsDesktop } from "../../../hooks/useIsDesktop";
import { inblockUsuariosLinks } from "../../../utils/inblockNavigationLinks";

import { InblockListaClientes } from "../inblock-lista-clientes/InblockListaClientes";
import styles from "../inblock-home/InblockHomePage.module.scss";
import { InblockClientePage } from "../inblock-cliente/InblockClientePage";
import { InblockObraPage } from "../inblock-obra/InblockObraPage";
import { InblockObrasDeCliente } from "../inblock-obras-de-cliente/InblockObrasDeCliente";
import { InblockAgregarClientePage } from "../inblock-agregar-cliente/InblockAgregarClientePage";
import { InblockCondicionesDeCliente } from "../inblock-condiciones-de-cliente/InblockCondicionesDeCliente";
import { InblockCondicionVentaPage } from "../inblock-condicion-venta/InblockCondicionVentaPage";
import { InblockListaPedidosPage } from "../inblock-lista-pedidos/InblockListaPedidosPage";
import { InblockAgregarObraPage } from "../inblock-agregar-obra/InblockAgregarObraPage";
import { InblockAgregarCondicionPage } from "../inblock-agregar-condicion/InblockAgregarCondicionPage";
import { InblockEditarClientePage } from "../inblock-editar-cliente/InblockEditarClientePage";
import { InblockPedidoPage } from "../inblock-pedido/InblockPedidoPage";
import { InblockAgregarPedidoPage } from "../inblock-agregar-pedido/InblockAgregarPedidoPage";
import { InblockEditarPedidoPage } from "../inblock-editar-pedido/InblockEditarPedidoPage";
import { InblockEditarObraPage } from "../inblock-editar-obra/InblockEditarObraPage";
import { InblockListaConductores } from "../inblock-lista-conductores/InblockListaConductores";
import { InblockAgregarConductorPage } from "../inblock-agregar-conductor/InblockAgregarConductorPage";
import { InblockListaViajesPage } from "../inblock-lista-viajes/InblockListaViajesPage";
import { InblockAgregarViajePage } from "../inblock-agregar-viaje/InblockAgregarViajePage";
import { InblockAgregarUsuarioPage } from "../inblock-agregar-usuario/InblockAgregarUsuarioPage";
import { InblockListaUsuariosPage } from "../inblock-lista-usuarios/InblockListaUsuariosPage";
import { InblockUsuarioPage } from "../inblock-usuario/InblockUsuarioPage";

export const InblockUsuariosPage = () => {
  const { path } = useRouteMatch();
  const isDesktop = useIsDesktop();
  
  return (
    <InblockLayout sectionTitle="Usuarios" navLinksArr={inblockUsuariosLinks}>
      <Switch>
        <Route exact path={`${path}`}>
          {isDesktop ? (
            <MainSectionCover text="Usuarios" empresa="inblock" />
          ) : (
            <div className={styles.svgContainer}>
              <div className={styles.mobileMainContent}>
                <div>
                  {inblockUsuariosLinks &&
                    inblockUsuariosLinks.map(userLink => (
                      <MobileMenuButtons
                        key={userLink.id}
                        userLinkObj={userLink}
                        app="inblock"
                      />
                    ))}
                </div>
              </div>
            </div>
          )}
        </Route>
        <Route exact path={`${path}/agregar-usuario`}>
          <div className="p-3">
            <InblockAgregarUsuarioPage />
            {/* <p>Agregar usuario  </p> */}
            
          </div>
        </Route>
        <Route exact path={`${path}/lista-usuarios`}>
          <div className="p-3">
            <InblockListaUsuariosPage />
            {/* <p>Lista de Usuarios  </p> */}
            
          </div>
        </Route>
        <Route exact path={`${path}/usuario/:id`}>
          <div className="p-3">
            <InblockUsuarioPage />
            {/* <p>Detalle de ususrio  </p> */}
            
          </div>
        </Route>
        

        



        <Route path={`${path}/*`}>
          <Redirect to="/inblock/home" />
        </Route>
      </Switch>
    </InblockLayout>
  );
};
