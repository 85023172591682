import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import Logo from "../../../assets/inblock/svg/Logo.svg";

import styles from "./InblockLoginPage.module.scss";
import { InblockLoginForm } from "../../../components/inblock/inblock-login-form/InblockLoginForm";
import { HomeButton } from "../../../components/home-button/HomeButton";

export const InblockLoginPage = () => {
  return (
    <div className={styles.loginPageContainer}>
      <div className={styles.svgContainer}>
        <Container fluid="md">
          <Row className="text-center justify-content-center">
            <Col md className={styles.logoContainer}>
              <img src={Logo} alt="logo" className={styles.logoImg} />
            </Col>
            <Col xs={8} sm={6} md className={styles.loginFormContainer}>
              <InblockLoginForm />
            </Col>
          </Row>
        </Container>
        <HomeButton />
      </div>
    </div>
  );
};
