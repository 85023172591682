import React from "react";
import { useParams } from "react-router-dom";
import { InblockDetalleCondicion } from "../../../components/inblock/inblock-detalles-condicion/InblockDetalleCondicion";
import { LoadingSpinner } from "../../../components/loading-spinner/LoadingSpinner";
import { useJwtFetchAndLoading } from "../../../hooks/useJwtFetchAndLoading";

export const InblockCondicionVentaPage = () => {
  const { id, idCliente } = useParams();

  const { data, isLoading } = useJwtFetchAndLoading(
    `${process.env.REACT_APP_INBLOCK_BASE_URL}clientes/${idCliente}/condicion-venta/${id}`,
    id
  );

  return isLoading ? (
    <LoadingSpinner />
  ) : (
    <InblockDetalleCondicion detalleCondicion={data} urlId={id} />
  );
};
