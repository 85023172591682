import React, { useContext, useState } from "react";
import { Link, useLocation, useParams, } from "react-router-dom";
import Table from "react-bootstrap/Table";
import { ModalRedirect } from "../../modal-redirect/ModalRedirect";

import { ReactReduxContext } from "../../../context/reactReduxContext";
import { setCurrentUser } from "../../../redux/user/userSlice";

import { SuccessModal } from "../../success-modal/SuccessModal";

export const InblockTablaPedidosViaje = ({ pedidos, setPedidos }) => {
  const [show, setShow] = useState(false);
  const [form, setForm] = useState({});
  const { pathname } = useLocation();
  const { id } = useParams();
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  
  const {
    dispatch,
    inblock: {
      inblockCurrentUserAccessToken,
      inblockCurrentUserRefreshToken,
      setInblockCurrentUser,
      
    },
  } = useContext(ReactReduxContext);

  const handleClose = () => setShow(false);

  

  const handleClick = async (e, pedido) => {
    try {
      e.target.classList.add("d-none");

      e.target.nextSibling.classList.remove("d-none");

      // let formData = new FormData();

      let is_active="ww";

      if (pedido.is_active === false) {
        is_active = true;
      }

      if (pedido.is_active === true) {
        is_active = false;
      }
      console.log(is_active)

      const formulario ={
        is_active:is_active,
        
      }

      let data = await fetch(
        `${process.env.REACT_APP_INBLOCK_BASE_URL}pedidos/${pedido.id}`,
        {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${inblockCurrentUserAccessToken}`,
          },
          body: JSON.stringify(formulario),
        }
      );

      let json = await data.json();

      if (json.expired) {
        dispatch(setCurrentUser({ token: json.token }));

        data = await fetch(
          `${process.env.REACT_APP_INBLOCK_BASE_URL}pedidos/${pedido.id}`,
          {
            method: "PATCH",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${inblockCurrentUserAccessToken}`,
            },
            body: JSON.stringify(formulario),
          }
        );

        json = await data.json();
      }

      if (data.status === 201 || data.status === 200) {
        setShow(true);
        

        setPedidos((prevState) => {
          const arr = [...prevState];

          const arrElementsStringifed = arr.map((el) => JSON.stringify(el));

          const idx = arrElementsStringifed.indexOf(JSON.stringify(pedido));

          arr.splice(idx, 1, json);

          return arr;
        });
        console.log("bien")
      }
    } catch (error) {
      console.log(error);
      alert(error);
    } finally {
      e.target.nextSibling.classList.add("d-none");
      e.target.classList.remove("d-none");
    }
  };


  return (
    <>
      <SuccessModal
        show={show}
        handleClose={handleClose}
        title="Pedido actualizado"
      />
      <div className="table-responsive p-0">
        <Table striped bordered hover className="text-center">
          <thead>
            <tr>
              <th>Id</th>
              <th>Cliente</th>
              <th>Fecha de entrega</th>
              <th>Horario</th>
              <th>Piezas</th>
              <th>Uso de material</th>
              <th>Color</th>
              <th>Tarimas</th>
              <th>Precio neto</th>
              <th>Tipo de pago</th>
              <th>Estatus de pago</th>

              {/* <th>Obra</th>
              {pathname.includes("logistica") && <th>Estatus de pago</th>}
              {pathname.includes("logistica") &&
                userRol === "Administracion" && <th>Comprobante de pago</th>} */}
              <th>Estatus de pedido</th>
              <th>Fecha de solicitud</th>

              <th>Asesor</th>
            </tr>
          </thead>
          <tbody>
            {pedidos.map((pedido) => (
              <tr
                key={pedido.id}
                // className={`${
                //   pathname.includes("logistica")
                //     ? userRol === "Administracion"
                //       ? pedido.is_active === "Activado"
                //         ? "table-success"
                //         : "table-danger"
                //       : ""
                //     : pathname.includes("produccion")
                //     ? userRol === "Operador"
                //       ? pedido.reporte_operador.salida_planta &&
                //         (!pedido.reporte_operador.llegada_obra ||
                //           !pedido.reporte_operador.inicio_bombeo ||
                //           !pedido.reporte_operador.fin_bombeo ||
                //           !pedido.reporte_operador.incidencia)
                //         ? "table-warning"
                //         : pedido.reporte_operador.causa_retraso
                //         ? "table-success"
                //         : "table-danger"
                //       : pedido.is_active === "Activado"
                //       ? "table-success"
                //       : "table-danger"
                //     : ""
                // }`}
              >
                <td>
                  <Link
                    to={
                      pathname.includes("logistica")
                        ? `/inblock/logistica/pedido/${pedido.id}`
                        : pathname.includes("viajes")
                        ? `/inblock/logistica/crear-viaje/${pedido.id}`
                        : pathname.includes("dashboard") &&
                          `/inblock/dashboard/pedido/${pedido.id}`
                    }
                  >
                    {pedido.id}
                  </Link>
                </td>
                <td>
                  <Link
                    to={
                      pathname.includes("pedidos")
                        ? `/inblock/logistica/pedido/${pedido.id}`
                        : pathname.includes("viajes")
                        ? `/inblock/logistica/crear-viaje/${pedido.id}`
                        : pathname.includes("dashboard") &&
                          `/inblock/dashboard/pedido/${pedido.id}`
                    }
                  >
                    {pedido.client.name}
                  </Link>
                </td>
                <td>
                  <Link
                    to={
                      pathname.includes("pedidos")
                        ? `/inblock/logistica/pedido/${pedido.id}`
                        : pathname.includes("viajes")
                        ? `/inblock/logistica/crear-viaje/${pedido.id}`
                        : pathname.includes("dashboard") &&
                          `/inblock/dashboard/pedido/${pedido.id}`
                    }
                  >
                    {pedido.delibery_date.slice(0, 10)}
                  </Link>
                </td>
                <td>
                  <Link
                    to={
                      pathname.includes("pedidos")
                        ? `/inblock/logistica/pedido/${pedido.id}`
                        : pathname.includes("viajes")
                        ? `/inblock/logistica/crear-viaje/${pedido.id}`
                        : pathname.includes("dashboard") &&
                          `/inblock/dashboard/pedido/${pedido.id}`
                    }
                  >
                    {pedido.delibery_hr}
                  </Link>
                </td>
                <td>
                  <Link
                    to={
                      pathname.includes("pedidos")
                        ? `/inblock/logistica/pedido/${pedido.id}`
                        : pathname.includes("viajes")
                        ? `/inblock/logistica/crear-viaje/${pedido.id}`
                        : pathname.includes("dashboard") &&
                          `/inblock/dashboard/pedido/${pedido.id}`
                    }
                  >
                    {pedido.pieces}
                  </Link>
                </td>
                <td>
                  <Link
                    to={
                      pathname.includes("pedidos")
                        ? `/inblock/logistica/pedido/${pedido.id}`
                        : pathname.includes("viajes")
                        ? `/inblock/logistica/crear-viaje/${pedido.id}`
                        : pathname.includes("dashboard") &&
                          `/inblock/dashboard/pedido/${pedido.id}`
                    }
                  >
                    {pedido.material_use}
                  </Link>
                </td>
                <td>
                  <Link
                    to={
                      pathname.includes("pedidos")
                        ? `/inblock/logistica/pedido/${pedido.id}`
                        : pathname.includes("viajes")
                        ? `/inblock/logistica/crear-viaje/${pedido.id}`
                        : pathname.includes("dashboard") &&
                          `/inblock/dashboard/pedido/${pedido.id}`
                    }
                  >
                    {pedido.material_color}
                  </Link>
                </td>
                <td>
                  <Link
                    to={
                      pathname.includes("pedidos")
                        ? `/inblock/logistica/pedido/${pedido.id}`
                        : pathname.includes("viajes")
                        ? `/inblock/logistica/crear-viaje/${pedido.id}`
                        : pathname.includes("dashboard") &&
                          `/inblock/dashboard/pedido/${pedido.id}`
                    }
                  >
                    {pedido.platforms}
                  </Link>
                </td>
                <td>
                  <Link
                    to={
                      pathname.includes("pedidos")
                        ? `/inblock/logistica/pedido/${pedido.id}`
                        : pathname.includes("viajes")
                        ? `/inblock/logistica/crear-viaje/${pedido.id}`
                        : pathname.includes("dashboard") &&
                          `/inblock/dashboard/pedido/${pedido.id}`
                    }
                  >
                    {"$ "+ pedido.net_price}
                  </Link>
                </td>
                <td>
                  <Link
                    to={
                      pathname.includes("pedidos")
                        ? `/inblock/logistica/pedido/${pedido.id}`
                        : pathname.includes("viajes")
                        ? `/inblock/logistica/crear-viaje/${pedido.id}`
                        : pathname.includes("dashboard") &&
                          `/inblock/dashboard/pedido/${pedido.id}`
                    }
                  >
                    {pedido.payment}
                  </Link>
                </td>
                <td>
                  <Link
                    to={
                      pathname.includes("pedidos")
                        ? `/inblock/logistica/pedido/${pedido.id}`
                        : pathname.includes("viajes")
                        ? `/inblock/logistica/crear-viaje/${pedido.id}`
                        : pathname.includes("dashboard") &&
                          `/inblock/dashboard/pedido/${pedido.id}`
                    }
                  >
                    {pedido.payment_status === "Por pagar" && "PP"}
                    {pedido.payment_status === "Pagado" && "P"}
                    {pedido.payment_status === "Credito" && "C"}
                  </Link>
                </td>
                <td>
                  <Link
                    to={
                      pathname.includes("pedidos")
                        ? `/inblock/logistica/pedido/${pedido.id}`
                        : pathname.includes("viajes")
                        ? `/inblock/logistica/crear-viaje/${pedido.id}`
                        : pathname.includes("dashboard") &&
                          `/inblock/dashboard/pedido/${pedido.id}`
                    }
                  >
                    {pedido.is_active === true && "Activado"}
                    {pedido.is_active === false && "Desactivado"}
                  </Link>

                      
                      {/* <>
                        <button
                          className="btn btn-primary btn-sm"
                          onClick={(e) => {
                            handleClick(e, pedido);
                          }}
                        >
                          {pedido.is_active === true && "Desactivar"}
                          {pedido.is_active === false && "Activar"}
                        </button>

                        <button
                          className="btn btn-primary d-none"
                          type="button"
                          disabled
                        >
                          <span
                            className="spinner-border spinner-border-sm"
                            role="status"
                            aria-hidden="true"
                          ></span>
                          <span className="visually-hidden">Loading...</span>
                        </button>
                      </> */}
                    
                </td>
                <td>
                  <Link
                    to={
                      pathname.includes("pedidos")
                        ? `/inblock/logistica/pedido/${pedido.id}`
                        : pathname.includes("viajes")
                        ? `/inblock/logistica/crear-viaje/${pedido.id}`
                        : pathname.includes("dashboard") &&
                          `/inblock/dashboard/pedido/${pedido.id}`
                    }
                  >
                    {pedido.created_at.slice(0, 10)}
                  </Link>
                </td>
                <td>
                  <Link
                    to={
                      pathname.includes("pedidos")
                        ? `/inblock/logistica/pedido/${pedido.id}`
                        : pathname.includes("viajes")
                        ? `/inblock/logistica/crear-viaje/${pedido.id}`
                        : pathname.includes("dashboard") &&
                          `/inblock/dashboard/pedido/${pedido.id}`
                    }
                  >
                    {pedido.assessor.name+" "+ pedido.assessor.last_name}
                  </Link>
                </td>
                
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    </>
  );
};