import React from "react";

import { useLocation } from "react-router-dom";

import styles from "../../../styles/inblock/inblock-header.module.scss";
import { InblockLogOutButton } from "../inblock-logout-btn/InblockLogOutBtn";

export const InblockHeader = ({ title }) => {
  const { pathname } = useLocation();

  return (
    <div className={styles.headerContainer}>
      <div style={{ color: "#FFDD00", width: "40px", height: "40px" }}>.</div>

      {pathname !== "/inblock/home" &&
        pathname !== "/inblock/logistica" &&
        pathname !== "/inblock/comercializacion" &&
        pathname !== "/inblock/usuarios" &&
        pathname !== "/inblock/produccion" &&
        pathname !== "/inblock/dashboard" && (
          <div className="d-flex align-items-center">
            <h2 style={{ margin: "0", color: "black" }}>{title}</h2>
          </div>
        )}

      <InblockLogOutButton />
    </div>
  );
};
