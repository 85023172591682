import React, { useContext, useEffect, useRef, useState } from "react";
import { useHistory, useParams, useLocation } from "react-router-dom";
import { ReactReduxContext } from "../../../context/reactReduxContext";
import { setInblockCurrentUser } from "../../../redux/inblock/user/userSlice";
import { jwtFetchData } from "../../../utils/jwtFetchData";



import { CustomSelect } from "../../custom-select/CustomSelect";
import { ModalRedirect } from "../../modal-redirect/ModalRedirect";

const formInitialState = {
  fullname: "",
};

export const InblockFormAgregarConductor = ({ formToEdit }) => {
  const [form, setForm] = useState(formInitialState);
  const [showConfirmModal, setShowConfirmModal] = useState(false);

  const {
    dispatch,
    inblock: {
      inblockCurrentUserAccessToken,
      inblockCurrentUserRefreshToken,
      setInblockCurrentUser,
    },
  } = useContext(ReactReduxContext);

  const history = useHistory();
  const { id } = useParams();
  const { pathname } = useLocation();

  const pagoRef = useRef();
  const municipioRef = useRef();
  const rfcInputRef = useRef();
  const cfdiInputRef = useRef();
  const metodoPagoInputRef = useRef();
  const formaPagoInputRef = useRef();
  const correoFacturasInputRef = useRef();
  const estadoInputRef = useRef();
  const municipioInputRef = useRef();

  useEffect(() => {
    
    const verificarSiClienteExiste = async () => {
      const json = await jwtFetchData(
        `${process.env.REACT_APP_INBLOCK_BASE_URL}clientes/${id}/`,
        `${process.env.REACT_APP_INBLOCK_BASE_URL}auth/token/refresh/`,
        inblockCurrentUserAccessToken,
        inblockCurrentUserRefreshToken,
        dispatch,
        setInblockCurrentUser
      );

      setForm({
        fullname: json.fullname,
  
      });

      
    };

    if (id) {
      verificarSiClienteExiste();
    }
  }, []);

  const handleChange = e => {
    const value = e.target.value;
    const name = e.target.name;

    // if (e.target.name === "pago") {
    //   if (e.target.value === "G") {
    //     setForm({
    //       ...form,
    //       pago: "G",
    //       rfc: "",
    //       CFDI: "",
    //       metodo_pago: "",
    //       forma_pago: "",
    //       correo_facturas: "",
    //     });

    //     rfcInputRef.current.disabled = true;
    //     cfdiInputRef.current.disabled = true;
    //     metodoPagoInputRef.current.disabled = true;
    //     formaPagoInputRef.current.disabled = true;
    //     correoFacturasInputRef.current.disabled = true;
    //   } else {
    //     setForm({
    //       ...form,
    //       pago: "F",
    //       CFDI: cfdiInputRef.current.value,
    //       metodo_pago: metodoPagoInputRef.current.value,
    //       forma_pago: formaPagoInputRef.current.value,
    //     });

    //     rfcInputRef.current.disabled = false;
    //     cfdiInputRef.current.disabled = false;
    //     metodoPagoInputRef.current.disabled = false;
    //     formaPagoInputRef.current.disabled = false;
    //     correoFacturasInputRef.current.disabled = false;
    //   }

    //   return;
    // }

    
    setForm({
      ...form,
      [name]: value,
    });
  };

  const handleSubmit = async (e, historyRef) => {
    e.preventDefault();

    const formulario = {
      fullname: form.fullname,
     
    };

    if (formToEdit) {
      let data = await fetch(
        `${process.env.REACT_APP_INBLOCK_BASE_URL}clientes/${id}`,
        {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${inblockCurrentUserAccessToken}`,
          },
          body: JSON.stringify(formulario),
        }
      );

      let json = await data.json();

      if (data.status === 401) {
        data = await fetch(
          `${process.env.REACT_APP_INBLOCK_BASE_URL}auth/token/refresh/`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ refresh: inblockCurrentUserRefreshToken }),
          }
        );

        json = await data.json();

        if (data.status === 200) {
          data = await fetch(
            `${process.env.REACT_APP_INBLOCK_BASE_URL}clientes/${id}`,
            {
              method: "PATCH",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${inblockCurrentUserAccessToken}`,
              },
              body: JSON.stringify(formulario),
            }
          );

          json = await data.json();

          dispatch(
            setInblockCurrentUser({
              access: json.access,
              refresh: inblockCurrentUserRefreshToken,
            })
          );
        }
      }

      if (data.status === 400) {
        if (json.email) {
          alert("Este correo ya ha sido registrado");
        }
        if (json.telefono) {
          alert("Este teléfono ya ha sido registrado");
          return;
        } else {
          alert(JSON.stringify(json));
        }
      }

      if (data.status === 201) {
        setShowConfirmModal(true);
      }
      if (data.status === 200) {
        setShowConfirmModal(true);
      }

      if (data.status === 406) {
        alert(json.error);
      }
    } else {
      let data = await fetch(
        `${process.env.REACT_APP_INBLOCK_BASE_URL}pedidos/agregar-conductor/`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${inblockCurrentUserAccessToken}`,
          },
          body: JSON.stringify(formulario),
        }
      );

      let json = await data.json();

      if (data.status === 401) {
        data = await fetch(
          `${process.env.REACT_APP_INBLOCK_BASE_URL}auth/token/refresh/`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ refresh: inblockCurrentUserRefreshToken }),
          }
        );

        json = await data.json();

        if (data.status === 200) {
          data = await fetch(
            `${process.env.REACT_APP_INBLOCK_BASE_URL}pedidos/agregar-condcutor/`,
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${inblockCurrentUserAccessToken}`,
              },
              body: JSON.stringify(formulario),
            }
          );

          json = await data.json();

          dispatch(
            setInblockCurrentUser({
              access: json.access,
              refresh: inblockCurrentUserRefreshToken,
            })
          );
        }
      }

      if (data.status === 400) {
        if (json.email) {
          alert("Este correo ya ha sido registrado");
        }
        if (json.telefono) {
          alert("Este teléfono ya ha sido registrado");
          return;
        } else {
          alert(JSON.stringify(json));
        }
      }

      if (data.status === 201) {
        setShowConfirmModal(true);
      }

      if (data.status === 406) {
        alert(json.error);
      }
    }
  };

  return (
    <div className="container">
      <ModalRedirect
        showConfirmModal={showConfirmModal}
        text={
          formToEdit
            ? "Se han actualizado correctamente los datos del conductor."
            : "Se ha creado correctamente el nuevo conductor."
        }
        link={
          formToEdit
            ? pathname.includes("logistica")
              ? pathname.includes("realizar-pedido")
                ? `/inblock/logistica/cliente/${id}/realizar-pedido`
                : `/inblock/logistica/cliente/${id}`
              : pathname.includes("comercializacion") &&
                `/inblock/comercializacion/cliente/${id}`
            : pathname.includes("logistica")
            ? pathname.includes("realizar-pedido")
              ? "/inblock/logistica/clientes-pedido"
              : "/inblock/logistica/conductores"
            : pathname.includes("comercializacion") &&
              "/inblock/comercializacion/clientes"
        }
      />
      <div className="row justify-content-center">
        <div className="col-12 col-sm-8">
          {formToEdit ? (
            <h3 className="text-center">Editar Conductor</h3>
          ) : (
            <h3 className="text-center">Agregar Conductor</h3>
          )}

          <form
            className="agregar-cliente-form"
            onSubmit={e => {
              handleSubmit(e, history);
            }}
          >
            <div className="row mb-2">
              <div className="col-12 col-md-6">
                <label htmlFor="nombre" className="form-label">
                  Nombre
                </label>
                <input
                  type="text"
                  name="fullname"
                  id="fullname"
                  value={form.fullname}
                  onChange={handleChange}
                  className="form-control"
                  autoComplete="off"
                  required
                />
              </div>

          
            </div>





            <div className="d-flex justify-content-end ">
              {formToEdit ? (
                <input
                  type="submit"
                  value="Guardar Cambios"
                  className="btn  mb-3 mt-2"
                  style={{ backgroundColor: "#6c757d", color: "white" }}
                />
              ) : (
                <input
                  type="submit"
                  value="Agregar"
                  className="btn mb-3 mt-2"
                  style={{ backgroundColor: "#6c757d", color: "white" }}
                />
              )}
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
