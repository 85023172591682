import React, { useContext } from "react";

/* import { ReactReduxContext } from "../../context/reactReduxContext";
import { setUserRole } from "../../redux/user/userSlice"; */

import LogOutIcon from "../../../assets/svg/iconoLogOut.svg";
import { ReactReduxContext } from "../../../context/reactReduxContext";

import { setInblockCurrentUser } from "../../../redux/inblock/user/userSlice";

export const InblockLogOutButton = () => {
  const { dispatch } = useContext(ReactReduxContext);

  const LogOut = async () => {
    /* const data = await fetch(
      `${process.env.REACT_APP_API_CONCRECO_BACKEND_URL}/api/logout?token=${authtoken}`,
      {
        headers: {
          Authorization: `Token ${authtoken}`,
        },
        mode: "no-cors",
      }
    ); */

    dispatch(setInblockCurrentUser(null));
    /* dispatch(setUserRole(null)); */
  };

  return (
    <div
      style={{
        width: "40px",
        display: "flex",
        alignItems: "center",
      }}
    >
      <img
        src={LogOutIcon}
        alt="logout"
        style={{
          cursor: "pointer",
          filter:
            "brightness(0) saturate(100%) invert(9%) sepia(13%) saturate(509%) hue-rotate(190deg) brightness(91%) contrast(92%)",
        }}
        onClick={LogOut}
      />
    </div>
  );
};
