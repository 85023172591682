import React from "react";
import { useParams } from "react-router-dom";
import { InblockDetallesPedido } from "../../../components/inblock/inblock-detalles-pedido/InblockDetallesPedido";
import { LoadingSpinner } from "../../../components/loading-spinner/LoadingSpinner";
import { useJwtFetchAndLoading } from "../../../hooks/useJwtFetchAndLoading";

export const InblockPedidoPage = () => {
  const { id } = useParams();

  const { data, isLoading } = useJwtFetchAndLoading(
    `${process.env.REACT_APP_INBLOCK_BASE_URL}pedidos/${id}/`,
    id
  );

  return isLoading ? (
    <LoadingSpinner />
  ) : (
    <InblockDetallesPedido detallesPedido={data} urlId={id} />
  );
};