import React from "react";
import { Link, useLocation } from "react-router-dom";

export const InblockTablaObrasDeCliente = ({ obras, idCliente }) => {
  const { pathname } = useLocation();
  return (
    <div className="table-responsive p-0">
      <table className="table table-striped table-hover table-bordered text-center">
        <thead>
          <tr>
            <th>ID</th>
            <th>Nombre</th>
            <th>Residente</th>
          </tr>
        </thead>
        <tbody className="text-reset">
          {obras.map((obra) => (
            <tr key={obra.id}>
              <td>
              {pathname.includes("pedido") && (
                <Link to={`/inblock/logistica/cliente/${idCliente}/obra/${obra.id}/realizar-pedido`}>{obra.id}</Link>
                )}

              {pathname.includes("logistica") && !pathname.includes("pedido") && (
                <Link to={`/inblock/logistica/cliente/${idCliente}/obra/${obra.id}`}>{obra.id}</Link>
                )}  
              </td>
              <td>
              {pathname.includes("pedido") && (
                <Link to={`/inblock/logistica/cliente/${idCliente}/obra/${obra.id}/realizar-pedido`}>{obra.name}</Link>
                )}

              {pathname.includes("logistica") && !pathname.includes("pedido") && (
                <Link to={`/inblock/logistica/cliente/${idCliente}/obra/${obra.id}`}>{obra.name}</Link>
                )}  
              </td>
              <td>
              {pathname.includes("pedido") && (
                <Link to={`/inblock/logistica/cliente/${idCliente}/obra/${obra.id}/realizar-pedido`}>{obra.resident}</Link>
                )}

              {pathname.includes("logistica") && !pathname.includes("pedido") && (
                <Link to={`/inblock/logistica/cliente/${idCliente}/obra/${obra.id}`}>{obra.resident}</Link>
                )}  
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};