import React, { useEffect, useState } from "react";
import { InblockFormAgregarCliente } from "../../../components/inblock/inblock-form-agregar-cliente/InblockFormAgregarCliente";

/* import { useFetchAndLoading } from "../../hooks/useFetchAndLoading"; */

import { LoadingSpinner } from "../../../components/loading-spinner/LoadingSpinner";
import { useJwtFetchAndLoading } from "../../../hooks/useJwtFetchAndLoading";
/* import { FormAgregarCliente } from "../../../components/form-agregar-cliente/FormAgregarCliente"; */

/* import CHOISES from "../../../utils/newClientChoices.json"; */

export const InblockAgregarClientePage = () => {
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  /* const { data, isLoading } = useJwtFetchAndLoading(
    `${process.env.REACT_APP_INBLOCK_BASE_URL}clientes/choices/`
  ); */

  useEffect(() => {
    (async () => {
      const res = await fetch("clientChoices.json");
      const json = await res.json();

      setData(json);
      setIsLoading(false);
    })();
  }, []);

  return (
    <>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <InblockFormAgregarCliente choicesData={data} />
      )}
    </>
  );
};
