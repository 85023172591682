import React from "react";
import { Redirect, Route, Switch, useRouteMatch } from "react-router-dom";
import { InblockLayout } from "../../../components/inblock/inblock-layout/InblockLayout";
import { MainSectionCover } from "../../../components/main-section-cover/MainSectionCover";
import { InblockListaClientes } from "../inblock-lista-clientes/InblockListaClientes";
import { MobileMenuButtons } from "../../../components/mobile-menu-buttons/MobileMenuButtons";
import { useIsDesktop } from "../../../hooks/useIsDesktop";
import { inblockFacturacionLinks } from "../../../utils/inblockNavigationLinks";

import styles from "../inblock-home/InblockHomePage.module.scss";
import { InblockClientePage } from "../inblock-cliente/InblockClientePage";
import { InblockObraPage } from "../inblock-obra/InblockObraPage";
import { InblockObrasDeCliente } from "../inblock-obras-de-cliente/InblockObrasDeCliente";
import { InblockAgregarClientePage } from "../inblock-agregar-cliente/InblockAgregarClientePage";
import { InblockCondicionesDeCliente } from "../inblock-condiciones-de-cliente/InblockCondicionesDeCliente";
import { InblockCondicionVentaPage } from "../inblock-condicion-venta/InblockCondicionVentaPage";
import { InblockListaVentasPage } from "../inblock-lista-ventas/InblockListaVentasPage";
import { InblockAgregarObraPage } from "../inblock-agregar-obra/InblockAgregarObraPage";
import { InblockAgregarCondicionPage } from "../inblock-agregar-condicion/InblockAgregarCondicionPage";
import { InblockEditarClientePage } from "../inblock-editar-cliente/InblockEditarClientePage";
import { InblockPedidoPage } from "../inblock-pedido/InblockPedidoPage";
import { InblockAgregarPedidoPage } from "../inblock-agregar-pedido/InblockAgregarPedidoPage";


export const InblockFacturacionPage = () => {
  const { path } = useRouteMatch();
  const isDesktop = useIsDesktop();

  return (
    <InblockLayout sectionTitle="Logística" navLinksArr={inblockFacturacionLinks}>
      <Switch>
        <Route exact path={`${path}`}>
          {isDesktop ? (
            <MainSectionCover text="Facturación" empresa="inblock" />
          ) : (
            <div className={styles.svgContainer}>
              <div className={styles.mobileMainContent}>
                <div>
                  {inblockFacturacionLinks &&
                    inblockFacturacionLinks.map(userLink => (
                      <MobileMenuButtons
                        key={userLink.id}
                        userLinkObj={userLink}
                        app="inblock"
                      />
                    ))}
                </div>
              </div>
            </div>
          )}
        </Route>
        <Route exact path={`${path}/ventas`}>
          <div className="p-3">
            <InblockListaVentasPage />
            {/* <p>Pagina condiciones de vneta</p> */}
          </div>
        </Route>
        
        <Route path={`${path}/*`}>
          <Redirect to="/inblock/home" />
        </Route>
      </Switch>
    </InblockLayout>
  );
};
