import React from "react";
import { FormAgregarUsuario } from "../../components/form-agregar-usuario/FormAgregarUsuario";

export const AgregarUsuarioPage = () => {
  return (
    <>
      <FormAgregarUsuario />
    </>
  );
};
