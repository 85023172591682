import React, { useContext, useEffect, useRef, useState } from "react";
import { useHistory, useParams, useLocation } from "react-router-dom";
import { ReactReduxContext } from "../../../context/reactReduxContext";
import { setInblockCurrentUser } from "../../../redux/inblock/user/userSlice";
import { jwtFetchData } from "../../../utils/jwtFetchData";

import {
  OpcionesSelectPago2,
  OpcionesSelectPago,
  OpcionesSelectCfdi,
  OpcionesSelectMetodoPago,
  OpcionesSelectFormaPago,
  OpcionesSelectContacto,
  OpcionesSelectGiro,
  OpcionesSelectTipoVenta,
} from "../../../utils/selects-opciones";

import { CustomSelect } from "../../custom-select/CustomSelect";
import { ModalRedirect } from "../../modal-redirect/ModalRedirect";

const formInitialState = {
  billing_type: "",
  cfdi: "",
  payment:"",
  payment_fiscal: "",
  payment_form: "",
  payment_method: "",
  sale_type: "",
 
};

export const InblockFormAgregarCondicion = ({ choicesData, formToEdit, clienteId }) => {
  const [form, setForm] = useState(formInitialState);
  const [showConfirmModal, setShowConfirmModal] = useState(false);

  const {
    dispatch,
    inblock: {
      inblockCurrentUserAccessToken,
      inblockCurrentUserRefreshToken,
      setInblockCurrentUser,
    },
  } = useContext(ReactReduxContext);

  const history = useHistory();
  const { id } = useParams();
  const { pathname } = useLocation();

  const pagoRef = useRef();
  const municipioRef = useRef();
  const rfcInputRef = useRef();
  const cfdiInputRef = useRef();
  const metodoPagoInputRef = useRef();
  const formaPagoInputRef = useRef();
  const correoFacturasInputRef = useRef();
  const estadoInputRef = useRef();
  const municipioInputRef = useRef();

  // useEffect(() => {
    
  //     setForm({
  //     billing_type: form.billing_type,
  //     cfdi: form.CFDI,
  //     payment_fiscal: form.pago,
  //     payment: form.payment,
  //     payment_form: form.forma_pago,
  //     payment_method: form.metodo_pago,
  //     sale_type: form.tipo_venta,
  //     client_id: clienteId,
  //     });
    
  // }, []);


  const handleChange = e => {
    const value = e.target.value;
    const name = e.target.name;

    if (e.target.name === "pago") {
      if (e.target.value === "F") {
        console.log("si");
        setForm({
          ...form,
          pago: "F",
          CFDI: cfdiInputRef.current.value,
          payment_method: metodoPagoInputRef.current.value,
          payment_form:formaPagoInputRef.current.value,
         
        });

        
        cfdiInputRef.current.disabled = false;
        metodoPagoInputRef.current.disabled = false;
        formaPagoInputRef.current.disabled = false;
        
      } else {
        console.log("no");
        setForm({
          ...form,
          pago: "G",
          CFDI: "N/A",
          metodo_pago: "N/A",
          forma_pago:"N/A",
         
        });

       
        cfdiInputRef.current.disabled = true;
        metodoPagoInputRef.current.disabled = true;
        formaPagoInputRef.current.disabled = true;
        
      }

      return;
    }

    // if (e.target.name === "estado_republica") {
    //   setForm({
    //     ...form,
    //     estado_republica: estadoInputRef.current.value,
    //     municipio: "",
    //   });

    //   return;
    // }

    setForm({
      ...form,
      [name]: value,
      //pago: e.target.value,
    });
  };

  const handleSubmit = async (e, historyRef) => {
    e.preventDefault();

    const formulario = {
      billing_type: form.billing_type,
      cfdi: form.CFDI,
      payment_fiscal: form.pago == "F" ? true : false,
      payment: form.payment,
      payment_form: form.forma_pago,
      payment_method: form.metodo_pago,
      sale_type: form.tipo_venta,
      client_id: clienteId,
    };

    if (formToEdit) {
      let data = await fetch(
        `${process.env.REACT_APP_INBLOCK_BASE_URL}clientes/${id}`,
        {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${inblockCurrentUserAccessToken}`,
          },
          body: JSON.stringify(formulario),
        }
      );

      let json = await data.json();

      if (data.status === 401) {
        data = await fetch(
          `${process.env.REACT_APP_INBLOCK_BASE_URL}auth/token/refresh/`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ refresh: inblockCurrentUserRefreshToken }),
          }
        );

        json = await data.json();

        if (data.status === 200) {
          data = await fetch(
            `${process.env.REACT_APP_INBLOCK_BASE_URL}clientes/${id}`,
            {
              method: "PATCH",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${inblockCurrentUserAccessToken}`,
              },
              body: JSON.stringify(formulario),
            }
          );

          json = await data.json();

          dispatch(
            setInblockCurrentUser({
              access: json.access,
              refresh: inblockCurrentUserRefreshToken,
            })
          );
        }
      }

      if (data.status === 400) {
        if (json.email) {
          alert("Este correo ya ha sido registrado");
        }
        if (json.telefono) {
          alert("Este teléfono ya ha sido registrado");
          return;
        } else {
          alert(JSON.stringify(json));
        }
      }

      if (data.status === 201) {
        setShowConfirmModal(true);
      }

      if (data.status === 406) {
        alert(json.error);
      }
    } else {
      let data = await fetch(
        `${process.env.REACT_APP_INBLOCK_BASE_URL}clientes/condicion-venta`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${inblockCurrentUserAccessToken}`,
          },
          body: JSON.stringify(formulario),
        }
      );

      let json = await data.json();

      if (data.status === 401) {
        data = await fetch(
          `${process.env.REACT_APP_INBLOCK_BASE_URL}auth/token/refresh/`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ refresh: inblockCurrentUserRefreshToken }),
          }
        );

        json = await data.json();

        if (data.status === 200) {
          data = await fetch(
            `${process.env.REACT_APP_INBLOCK_BASE_URL}clientes/condicion-venta`,
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${inblockCurrentUserAccessToken}`,
              },
              body: JSON.stringify(formulario),
            }
          );

          json = await data.json();

          dispatch(
            setInblockCurrentUser({
              access: json.access,
              refresh: inblockCurrentUserRefreshToken,
            })
          );
        }
      }

      if (data.status === 400) {
        if (json.email) {
          alert("Este correo ya ha sido registrado");
        }
        if (json.telefono) {
          alert("Este teléfono ya ha sido registrado");
          return;
        } else {
          alert(JSON.stringify(json));
        }
      }

      if (data.status === 201) {
        setShowConfirmModal(true);
      }

      if (data.status === 406) {
        alert(json.error);
      }
    }
  };

  return (
    <div className="container">
      <ModalRedirect
        showConfirmModal={showConfirmModal}
        text={
          formToEdit
            ? "Se han actualizado correctamente los datos del cliente."
            : "Se ha creado correctamente la condición de venta."
        }
        link={
          formToEdit
            ? pathname.includes("logistica")
              ? pathname.includes("realizar-pedido")
                ? `/inblock/logistica/cliente/${id}/realizar-pedido`
                : `/inblock/logistica/cliente/${id}`
              : pathname.includes("comercializacion") &&
                `/inblock/comercializacion/cliente/${id}`
            : pathname.includes("logistica")
            ? pathname.includes("realizar-pedido")
              ? "/inblock/logistica/clientes-pedido"
              : "/inblock/logistica/clientes"
            : pathname.includes("comercializacion") &&
              "/inblock/comercializacion/clientes"
        }
      />
      <div className="row justify-content-center">
        <div className="col-12 col-sm-8">
          {formToEdit ? (
            <h3 className="text-center">Editar Cliente</h3>
          ) : (
            <h3 className="text-center">Agregar Condicion de Venta</h3>
          )}

          <form
            className="agregar-cliente-form"
            onSubmit={e => {
              handleSubmit(e, history);
            }}
          >

            
            {/* <div className="mb-2">
                <CustomSelect
                  datos={OpcionesSelectPago2}
                  handleChange={handleChange}
                  isRequired={false}
                  form={form}
                />
              </div> */}
              <div className="mb-2">
              {/* <div className="col-12 col-md-6"> */}
                <CustomSelect
                  datos={OpcionesSelectPago}
                  handleChange={handleChange}
                  isRequired={true}
                  form={form}
                  selectRef={pagoRef}
                />
              {/* </div> */}
              </div>

              <div className="mb-2">
                <CustomSelect
                  datos={OpcionesSelectCfdi}
                  handleChange={handleChange}
                  selectRef={cfdiInputRef}
                  isRequired={true}
                  form={form}
                />
              </div>

              <div className="mb-2">
                <CustomSelect
                  datos={OpcionesSelectMetodoPago}
                  handleChange={handleChange}
                  selectRef={metodoPagoInputRef}
                  isRequired={true}
                  form={form}
                />
              </div>

              <div className="mb-2">
                <CustomSelect
                  datos={OpcionesSelectFormaPago}
                  handleChange={handleChange}
                  selectRef={formaPagoInputRef}
                  isRequired={true}
                  form={form}
                />
              </div>

              <div className="mb-2">
                <CustomSelect
                  datos={OpcionesSelectTipoVenta}
                  handleChange={handleChange}
                  isRequired={true}
                  form={form}
                />
              </div>


            
            <div className="mb-2">
              <label htmlFor="billing_type" className="form-label">
                Tipo Facturación
              </label>
              <input
                type="text"
                name="billing_type"
                id="billing_type"
                value={form.resident}
                onChange={handleChange}
                className="form-control"
                autoComplete="off"
                required
              />
            </div>

           





            <div className="d-flex justify-content-end ">
              {formToEdit ? (
                <input
                  type="submit"
                  value="Guardar Cambios"
                  className="btn  mb-3 mt-2"
                  style={{ backgroundColor: "#00C08B", color: "white" }}
                />
              ) : (
                <input
                  type="submit"
                  value="Agregar"
                  className="btn mb-3 mt-2"
                  style={{ backgroundColor: "#00C08B", color: "white" }}
                />
              )}
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};